import axios from '../../../axiosInstance.ts'
import {HttpGetConversationParams} from '@/features/conversation/types.ts'
import {HttpExportParams} from '@/types/commons.ts'

/**
 * httpGetConversation
 * Here we will fetch all the conversation data
 */

export const httpGetConversation = async (params: HttpGetConversationParams) => {
    return await axios.get(`/conversations`, {params})
}

/**
 * httpGetConversation
 * Here we will fetch a CSV conversation
 */

export const httpExportConversation = async (params: HttpExportParams) => {
    return await axios.get(`/conversations/export`, {params})
}
