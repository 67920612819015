import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {ArrowLeftIcon, CheckIcon} from '@components/ui/icon/Icon.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledAuthWrapper} from '@pages/login/style.ts'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {StyledEmailBoxWrapper} from '@pages/email-sent/style.ts'

export const EmailSent: React.FC = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <StyledAuthWrapper>
                <StyledEmailBoxWrapper>
                    <Flexbox justify={'center'}>
                        <IconContainer size={'lg'}>
                            <CheckIcon />
                        </IconContainer>
                    </Flexbox>
                    <Flexbox direction={'column'} gap={3} align={'center'}>
                        <h4>{t('emailSent:title')}</h4>
                        <p>{t('emailSent:subtitle')}</p>
                        <p>{t('emailSent:spam')}</p>
                    </Flexbox>
                    <ButtonLink to={routes.LOGIN.path} variant={'tertiary'}>
                        <ArrowLeftIcon />
                        {t('emailSent:cta')}
                    </ButtonLink>
                </StyledEmailBoxWrapper>
            </StyledAuthWrapper>
        </Container>
    )
}

EmailSent.displayName = 'EmailSent'
