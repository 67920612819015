import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpChangePassword} from '@/features/auth/services/auth.http.ts'
import {ErrorResponseData} from '@/types/commons.ts'
import {HttpChangePasswordBody} from '@/features/auth/types.ts'

type useLoginProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useChangePassword = (options?: useLoginProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.CHANGE_PASSWORD],
        mutationFn: (params: HttpChangePasswordBody) => httpChangePassword(params),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: () => options?.onSuccess?.()
    })
}
