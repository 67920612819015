import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetConversation} from '@/features/conversation/services/conversation.http.ts'
import {HttpGetConversationParams, HttpGetConversationResponse} from '@/features/conversation/types.ts'

export const useGetConversation = (params: Omit<HttpGetConversationParams, 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.CONVERSATION, params.startDate, params.endDate, params.type],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetConversation({
                    ...params,
                    page: pageParam
                }),
                onZodError: captureException,
                responseShape: HttpGetConversationResponse
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    const totalConversation = query.data?.pages[0].meta.total
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData,
        totalConversation
    }
}
