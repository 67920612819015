import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {
    LOGIN_FORM_MODEL,
    LoginFormSchema,
    LoginValidationSchema
} from '@/features/auth/components/login-form/LoginFormModel.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import React from 'react'
import {useAuthStore} from '@/features/auth/store/store.ts'
import toast from 'react-hot-toast'
import {useLogin} from '@/features/auth/services/query/useLogin.ts'
import {ToastBody} from '@components/ui/toast-bar/ToastBody.tsx'

export const LoginForm: React.FC = () => {
    const {t} = useTranslation()
    const setAuthData = useAuthStore(state => state.setAuthData)
    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid}
    } = useForm<LoginValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(LoginFormSchema)
    })
    //login mutation
    const {mutate: login, isPending} = useLogin({
        onError: () => {
            toast.error(<ToastBody title={t('login:error:email_password')} subtitle={t('login:error:try_again')} />, {
                position: 'bottom-center'
            })
        },
        onSuccess: data => {
            setAuthData(data)
        }
    })
    const onSubmit: SubmitHandler<LoginValidationSchema> = data => {
        login(data)
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flexbox direction={'column'} gap={6} align={'stretch'}>
                <Flexbox direction={'column'} gap={5}>
                    <InputText
                        label={t(LOGIN_FORM_MODEL.email.label)}
                        type={'email'}
                        touched={touchedFields.email}
                        placeholder={t(LOGIN_FORM_MODEL.email.placeholder)}
                        errorMessage={t(errors.email?.message || '')}
                        {...register(t(LOGIN_FORM_MODEL.email.name))}
                    />
                    <InputText
                        label={t(LOGIN_FORM_MODEL.password.label)}
                        type={'password'}
                        visibilityToggle={true}
                        touched={touchedFields.password}
                        errorMessage={t(errors.password?.message || '')}
                        placeholder={t(LOGIN_FORM_MODEL.password.placeholder)}
                        {...register(t(LOGIN_FORM_MODEL.password.name))}
                        helpText={t(LOGIN_FORM_MODEL.password.helpText)}
                    />
                </Flexbox>
                <Flexbox justify={'end'}>
                    <ButtonLink to={routes.FORGOT_PASSWORD.path} variant={'tertiary'}>
                        {t('login:secondary_cta')}
                    </ButtonLink>
                </Flexbox>
                <Button fullWidth variant={'primary'} size={'md'} type={'submit'} disabled={!isValid || isPending}>
                    {t('login:primary_cta')}
                </Button>
            </Flexbox>
        </form>
    )
}
