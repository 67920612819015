import styled, {css, DefaultTheme} from 'styled-components'
import {NavLink} from 'react-router-dom'

type StyledNavLinkProps = {
    size: 'sm' | 'md' | 'lg'
    variant: 'ghost' | 'primary'
}
export const StyledNavLink = styled(NavLink)<StyledNavLinkProps>`
    ${({theme: {spacing}}) => css`
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: ${spacing * 2}px;
        border: 1px solid transparent;
        border-radius: 20px;
        width: auto;
        transition: ease-in-out 200ms;
        cursor: pointer;
        font-weight: 500;
        text-transform: capitalize;
        & svg {
            width: 20px;
        }
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
    ${({size, theme}) => SizeStyles(theme)[size]};
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    ghost: css`
        background: transparent;
        color: ${palette.neutral['700']};

        &:not(:disabled):hover {
            background: ${palette.neutral['50']};
        }

        &:not(:disabled).active {
            background: ${palette.neutral['50']};
        }

        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.neutral['300']};
            cursor: not-allowed;
        }
    `,
    primary: css`
        background: transparent;
        color: ${palette.primary['600']};

        &:not(:disabled):hover {
            background: ${palette.primary['25']};
        }

        &:not(:disabled).active {
            background: ${palette.primary['50']};
            border: 1px solid ${palette.primary[100]};
        }

        &:disabled {
            background: ${palette.neutral.white};
            color: ${palette.primary['300']};
            cursor: not-allowed;
        }
    `
})

const SizeStyles = ({spacing, typography}: DefaultTheme) => ({
    sm: css`
        height: 36px;
        padding: ${spacing}px ${spacing * 4}px;
        ${typography.textSm}
    `,
    md: css`
        height: 44px;
        padding: ${spacing * 2.5}px ${spacing * 4.5}px;
        ${typography.textMd}
    `,
    lg: css`
        height: 60px;
        padding: ${spacing * 4}px ${spacing * 7}px;
        ${typography.textLg}
    `
})
