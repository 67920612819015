import {Dispatch, FC, SetStateAction} from 'react'
import {Conversation} from '@/features/conversation/types.ts'
import {
    StyledConversationItemWrapper,
    StyledTitleContainer
} from '@/features/conversation/components/conversation-list/style.ts'
import dayjs from 'dayjs'
import {FaceSadIcon, FaceSmileIcon} from '@components/ui/icon/Icon.tsx'
import {EmptyPlaceholder} from '@components/commons/table/empty-placeholder/EmptyPlaceholder.tsx'
import {ErrorMessage} from '@components/commons/table/error-message/ErrorMessage.tsx'
import {Virtuoso} from 'react-virtuoso'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {useTranslation} from 'react-i18next'

interface ConversationListProps {
    conversations: Conversation[]
    isError?: boolean
    isChangingPage?: boolean
    isLoading: boolean
    onChangePage?: (lastItemIndex: number) => void
    conversationActiveIndex: number
    setCurrentConversationIndex: Dispatch<SetStateAction<number>>
}

export const ConversationList: FC<ConversationListProps> = ({
    conversations,
    isError,
    isChangingPage,
    isLoading,
    onChangePage,
    conversationActiveIndex,
    setCurrentConversationIndex
}) => {
    const {t} = useTranslation()
    return (
        <Virtuoso
            data={conversations}
            overscan={100}
            endReached={isError ? undefined : onChangePage}
            itemContent={(itemIndex, item) => {
                return (
                    <StyledConversationItemWrapper
                        onClick={() => setCurrentConversationIndex(itemIndex)}
                        $isActive={conversationActiveIndex === itemIndex}
                        justify={'space-between'}
                        gap={8}
                    >
                        <StyledTitleContainer title={item.title || undefined}>
                            <h4>{item.title || t('conversations:conversation')}</h4>
                            <p>{dayjs(item.createdAt).format('ddd DD MMM, HH:mm')}</p>
                        </StyledTitleContainer>
                        <Flexbox align={'center'} gap={2}>
                            <p>{item.ticketId && `#${item.ticketId}`}</p>
                            {item.isSatisfied != null ? (
                                item.isSatisfied ? (
                                    <FaceSmileIcon size={18} />
                                ) : (
                                    <FaceSadIcon size={18} />
                                )
                            ) : null}
                        </Flexbox>
                    </StyledConversationItemWrapper>
                )
            }}
            components={{
                EmptyPlaceholder: () => {
                    if (isLoading) {
                        return (
                            <Flexbox direction={'column'} gap={2}>
                                <Skeleton height={56} />
                                <Skeleton height={56} />
                                <Skeleton height={56} />
                            </Flexbox>
                        )
                    }
                    if (isError) {
                        return null
                    }

                    return <EmptyPlaceholder />
                },
                Footer: () => {
                    if (isLoading) {
                        return null
                    }
                    if (isError) {
                        return <ErrorMessage />
                    }
                    if (isChangingPage) {
                        return (
                            <Flexbox direction={'column'} gap={2}>
                                <Skeleton height={56} />
                                <Skeleton height={56} />
                                <Skeleton height={56} />
                            </Flexbox>
                        )
                    }

                    return null
                }
            }}
        />
    )
}
