import React, {FC, useMemo} from 'react'
import {Table, TableSorter} from '@components/commons/table/Table.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useTranslation} from 'react-i18next'
import {TableDataCell} from '@components/commons/table/TableDataCell/TableDataCell.tsx'
import {Ticket, TicketsSearchParams} from '@/features/tickets/types.ts'
import {capitalize} from '@utilities/helpers.ts'
import {CheckIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {EmptySearch} from '@components/commons/empty-search/EmptySearch.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {ticketFormatData} from '@/features/tickets/utils.ts'
import {useUpdateTicket} from '@/features/tickets/services/query/useUpdateTicket.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'

interface TicketsTableProps {
    tickets: Ticket[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    onChangePage?: () => void
    onResetSearchCb?: () => void
    searchValue?: string
    setCurrentTicketIndex: React.Dispatch<number>
    currentTicketIndex: number | null
    sorter?: TableSorter<TicketsSearchParams['orderBy']> | undefined
    onClickRow?: (itemIndex: number) => void
    currentSection: 'open' | 'closed'
}
export const TicketsTable: FC<TicketsTableProps> = ({
    tickets,
    isChangingPage,
    isError,
    isLoading,
    onChangePage,
    onResetSearchCb,
    searchValue,
    setCurrentTicketIndex,
    currentTicketIndex,
    sorter,
    onClickRow,
    currentSection
}) => {
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const userId = useAuthStore(state => state.user?.id)
    const {t} = useTranslation()
    const {mutate: updateTicket, isPending: pendingUpdate} = useUpdateTicket({
        onSuccess: () =>
            currentSection === 'open'
                ? toast.success(t('tickets:ticket_to_closed'))
                : toast.success(t('tickets:ticket_to_open'))
    })

    const oncClickMoveToClosed = (ticketId: number) => {
        if (userId) {
            updateTicket({ticketId, data: {completedBy: userId, completedAt: dayjs().toISOString()}})
        }
    }
    const oncClickMoveToOpen = (ticketId: number) => {
        updateTicket({ticketId, data: {completedBy: null, completedAt: null}})
    }
    return (
        <Table
            data={tickets}
            isMobile={isMobile}
            isLoading={isLoading}
            onChangePage={onChangePage}
            isChangingPage={isChangingPage}
            isError={isError}
            searchValue={searchValue}
            selectedRowIndex={currentTicketIndex}
            sorter={sorter}
            onClickRow={onClickRow}
            columns={[
                {
                    label: t('tickets:table:labels:id'),
                    width: '10%',
                    sortName: 'id',
                    cellRender: (ticket, index) => (
                        <TableDataCell>
                            <Button variant={'tertiary'} onClick={() => setCurrentTicketIndex(index)}>
                                {`#${ticket.id}`}
                            </Button>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:typology'),
                    width: '10%',
                    sortName: 'type',
                    cellRender: ticket => (
                        <TableDataCell>
                            <p>{t(`tickets:table:typology:${ticket.type}`)}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:reported_by'),
                    width: '15%',
                    sortName: 'sender_last_name',
                    cellRender: ticket => (
                        <TableDataCell>
                            <h4>{`${capitalize(ticket.senderFirstName)} ${capitalize(ticket.senderLastName)}`}</h4>
                            <span title={ticket.email}>{ticket.email}</span>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:phone_number'),
                    width: '15%',
                    cellRender: ticket => (
                        <TableDataCell>
                            <p>{ticket.phoneNumber}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:behalf_of'),
                    width: '15%',
                    sortName: 'patient_last_name',
                    cellRender: ticket => (
                        <TableDataCell>
                            {ticket.patientFirstName && ticket.patientLastName ? (
                                <h4>{`${capitalize(ticket.patientFirstName)} ${capitalize(ticket.patientLastName)}`}</h4>
                            ) : (
                                <span>{t('tickets:table:no_delegation')}</span>
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: t('tickets:table:labels:created_at'),
                    width: '15%',
                    sortName: 'created_at',
                    cellRender: ticket => (
                        <TableDataCell>
                            <h4>{ticketFormatData(ticket.createdAt)}</h4>
                        </TableDataCell>
                    )
                },
                {
                    label: '',
                    width: '20%',
                    cellRender: ticket => (
                        <TableDataCell>
                            {ticket.completedAt ? (
                                <Button
                                    variant={'tertiary'}
                                    disabled={pendingUpdate}
                                    onClick={() => oncClickMoveToOpen(ticket.id)}
                                >
                                    {t('tickets:table:mark_open')}
                                </Button>
                            ) : (
                                <Button
                                    variant={'secondary'}
                                    disabled={pendingUpdate}
                                    onClick={() => oncClickMoveToClosed(ticket.id)}
                                >
                                    <CheckIcon />
                                    {t('tickets:table:mark_closed')}
                                </Button>
                            )}
                        </TableDataCell>
                    )
                }
            ]}
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    icon={<SearchLgIcon />}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                />
            }
        />
    )
}
