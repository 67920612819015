import {useTheme} from 'styled-components'
import {LoadingScreenBox, LoadingScreenWrapper} from '@components/commons/loading-screen/style.ts'
import {Container} from '@components/ui/container/Container.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

export const LoadingScreen = () => {
    const theme = useTheme()

    return (
        <Container>
            <LoadingScreenWrapper>
                <LoadingScreenBox>
                    <Spinner size={48} color={theme.palette.neutral['500']} />
                </LoadingScreenBox>
            </LoadingScreenWrapper>
        </Container>
    )
}

LoadingScreen.displayName = 'LoadingScreen'
