import {FC} from 'react'
import {StyledEmptyTicketContainer} from '@/features/tickets/components/empty-tickets/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {FileQuestion02Icon} from '@components/ui/icon/Icon.tsx'

interface EmptyTicketsProps {
    title: string
    description: string
}
export const EmptyTickets: FC<EmptyTicketsProps> = ({title, description}) => {
    return (
        <StyledEmptyTicketContainer justify={'center'} align={'center'}>
            <Flexbox direction={'column'} gap={4} align={'center'}>
                <FileQuestion02Icon />
                <Flexbox direction={'column'} gap={1} align={'center'}>
                    <h4>{title}</h4>
                    <p>{description}</p>
                </Flexbox>
            </Flexbox>
        </StyledEmptyTicketContainer>
    )
}
