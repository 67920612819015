import styled, {DefaultTheme, css} from 'styled-components'

type StyledChipProps = {
    $variant: 'success' | 'primary' | 'neutral'
    $radiusFull: boolean
}

type ColorsProps = {
    theme: DefaultTheme
}

export const StyledChip = styled.div<StyledChipProps>(
    ({theme: {spacing, typography}, $variant, $radiusFull}) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        gap: ${spacing}px;
        padding: ${spacing / 2}px ${spacing * 2}px;
        border: 1px solid;
        font-weight: 500;
        border-radius: ${$radiusFull ? '16px' : '6px'};
        svg {
            order: 1;
        }
        ${typography.textXs};
        ${({theme}) => ColorStyles({theme})[$variant]};
    `
)

const ColorStyles = ({theme: {palette}}: ColorsProps) => ({
    success: css`
        background-color: ${palette.success['50']};
        color: ${palette.success['700']};
        border-color: ${palette.success['200']};
    `,
    primary: css`
        background-color: ${palette.primary['50']};
        color: ${palette.primary['700']};
        border-color: ${palette.primary['200']};
    `,
    neutral: css`
        background-color: transparent;
        color: ${palette.neutral['700']};
        border-color: ${palette.neutral['100']};
    `
})
