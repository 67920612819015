import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {Key01Icon} from '@components/ui/icon/Icon.tsx'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

import {ChangePasswordForm} from '@/features/auth/components/change-password-form/ChangePasswordForm.tsx'
import {StyledAuthBoxWrapper, StyledAuthWrapper} from '@pages/login/style.ts'

export const ChangePassword: React.FC = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <StyledAuthWrapper>
                <StyledAuthBoxWrapper>
                    <Flexbox justify={'center'}>
                        <IconContainer size={'lg'}>
                            <Key01Icon />
                        </IconContainer>
                    </Flexbox>
                    <Flexbox direction={'column'} gap={3} align={'center'}>
                        <h4>{t('changePassword:title')}</h4>
                        <p>{t('changePassword:subtitle')}</p>
                    </Flexbox>
                    <ChangePasswordForm />
                </StyledAuthBoxWrapper>
            </StyledAuthWrapper>
        </Container>
    )
}

ChangePassword.displayName = 'ChangePassword'
