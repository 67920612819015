import {routes} from '@utilities/constants/routes.tsx'
import {Permission} from '@/features/users/types.ts'
import {MenuItem} from '@components/commons/header/Header.tsx'

export const roleBasedRedirect = (accessToken: string | undefined, permit: Permission | undefined | null) => {
    if (!accessToken) {
        return routes.LOGIN.path
    }

    switch (permit?.name) {
        case 'conversation':
            return routes.CONVERSATION_HISTORY.path
        case 'ticket':
            return routes.TICKETS.path
        case 'dashboard':
            return routes.DASHBOARD.path
        case 'user':
            return routes.USERS.path
        default:
            return '/' //shows 404
    }
}

export const getPermissionsArray = (permissions: string[] | undefined): MenuItem[] => {
    const permissionMapping: Record<string, MenuItem> = {
        conversation: {name: 'routeNames:chats', path: '/chats'},
        ticket: {name: 'routeNames:ticket', path: '/tickets'},
        dashboard: {name: 'routeNames:dashboard', path: '/dashboard'},
        user: {name: 'routeNames:users', path: '/users'}
    }
    const customOrder = ['dashboard', 'conversation', 'ticket', 'user']
    const validPermissions = permissions?.filter(permission => permission in permissionMapping)
    return validPermissions
        ? validPermissions
              .sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b))
              .map(permission => permissionMapping[permission])
        : []
}
