import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {TableColumn} from '@components/commons/table/Table.tsx'
import {
    StyledTCell,
    StyledTCellColumn,
    StyledTCellValue,
    StyledTRow,
    StyledTRowWrapper
} from '@/components/commons/table/mobile-t-body/style'

interface MobileSkeletonProps<TData extends unknown[], TSortName extends string | undefined> {
    columns: TableColumn<TData, TSortName>[]
}

export const MobileSkeleton = <TData extends unknown[], TSortName extends string | undefined>({
    columns
}: MobileSkeletonProps<TData, TSortName>) => {
    return (
        <StyledTRowWrapper $isActive={false}>
            <StyledTRow>
                {columns.map((column, index) => (
                    <StyledTCell key={index}>
                        {column.label && (
                            <StyledTCellColumn>
                                <Skeleton height={14} />
                            </StyledTCellColumn>
                        )}
                        <StyledTCellValue>
                            <Skeleton height={14} />
                        </StyledTCellValue>
                    </StyledTCell>
                ))}
            </StyledTRow>
        </StyledTRowWrapper>
    )
}

MobileSkeleton.displayName = 'TableMobileSkeleton'
