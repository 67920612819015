import {FC, HTMLAttributes} from 'react'
import {StyledTableDataCell} from './style'

interface TableDataCellProps extends HTMLAttributes<HTMLDivElement> {
    className?: string
}

export const TableDataCell: FC<TableDataCellProps> = ({className, children, ...rest}) => {
    return (
        <StyledTableDataCell className={className} {...rest}>
            {children}
        </StyledTableDataCell>
    )
}
