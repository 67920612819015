import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {User} from '@/features/users/types.ts'
import {
    StyledDeleteUserModalBody,
    StyledDeleteUserModalCloseButton,
    StyledDeleteUserModalContent,
    StyledDeleteUserModalFooter,
    StyledDeleteUserModalHeader
} from '@/features/users/components/user-delete-modal/style.ts'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {useDeleteUser} from '@/features/users/services/query/useDeleteUser.ts'
import toast from 'react-hot-toast'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

interface UserDeleteModalProps {
    userToDelete: User
    onCloseDeleteModal: () => void
}
export const UserDeleteModal: React.FC<UserDeleteModalProps> = ({userToDelete, onCloseDeleteModal}) => {
    const {t} = useTranslation()
    const {mutate: deleteUser, isPending: deleteUserPending} = useDeleteUser({
        onSuccess: () => {
            toast.success(t('users:deleteModal:success'))
            onCloseDeleteModal()
        },
        onError: () => toast.error(t('users:deleteModal:error'))
    })

    const onClickDeleteUser = () => {
        deleteUser(userToDelete.id)
    }

    return (
        <Modal onOverlayClick={onCloseDeleteModal} width={444}>
            <StyledDeleteUserModalContent>
                <StyledDeleteUserModalHeader justify={'center'}>
                    <StyledDeleteUserModalCloseButton
                        shape={'square'}
                        size={'sm'}
                        variant={'ghost'}
                        onClick={onCloseDeleteModal}
                    >
                        <XCloseIcon />
                    </StyledDeleteUserModalCloseButton>
                    <p>{t('users:deleteModal:header')}</p>
                </StyledDeleteUserModalHeader>
                <StyledDeleteUserModalBody direction={'column'} gap={2}>
                    <h4>
                        {t('users:deleteModal:title', {fullName: `${userToDelete.firstName} ${userToDelete.lastName}`})}
                    </h4>
                    <p>{t('users:deleteModal:subtitle')}</p>
                </StyledDeleteUserModalBody>
                <StyledDeleteUserModalFooter justify={'space-between'}>
                    <Button variant={'ghost'} onClick={onCloseDeleteModal}>
                        {t('users:deleteModal:secondary_cta')}
                    </Button>
                    <Button variant={'primaryDanger'} onClick={onClickDeleteUser} disabled={deleteUserPending}>
                        {t('users:deleteModal:primary_cta')}
                        {deleteUserPending && <Spinner size={24} />}
                    </Button>
                </StyledDeleteUserModalFooter>
            </StyledDeleteUserModalContent>
        </Modal>
    )
}

UserDeleteModal.displayName = 'UserDeleteModal'
