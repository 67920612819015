import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {CreateUserForm} from '@/features/users/components/create-user-form/CreateUserForm.tsx'
import {
    StyledCreateUserModalCloseButton,
    StyledCreateUserModalContent,
    StyledCreateUserModalHeader
} from '@/features/users/components/user-create-modal/style.ts'

interface UserCreateModalProps {
    onCloseCreateModal: () => void
}
export const UserCreateModal: React.FC<UserCreateModalProps> = ({onCloseCreateModal}) => {
    const {t} = useTranslation()

    return (
        <Modal onOverlayClick={onCloseCreateModal} width={444}>
            <StyledCreateUserModalContent>
                <StyledCreateUserModalHeader justify={'center'}>
                    <StyledCreateUserModalCloseButton
                        shape={'square'}
                        size={'sm'}
                        variant={'ghost'}
                        onClick={onCloseCreateModal}
                    >
                        <XCloseIcon />
                    </StyledCreateUserModalCloseButton>
                    <p>{t('users:createModal:header')}</p>
                </StyledCreateUserModalHeader>
                <CreateUserForm onCloseModal={onCloseCreateModal} />
            </StyledCreateUserModalContent>
        </Modal>
    )
}

UserCreateModal.displayName = 'UserDeleteModal'
