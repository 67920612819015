import {create} from 'zustand'
import {AuthState, HttpLoginResponse} from '@/features/auth/types.ts'
import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@/features/auth/constants.ts'
import {User} from '@/features/users/types.ts'

type AuthStoreActions = {
    reset: () => void
    setAuthData: (data: HttpLoginResponse) => void
    setUser: (data: User) => void
}

export const useAuthStore = create<Partial<AuthState> & AuthStoreActions>()(set => ({
    user: null,
    accessToken: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN) || undefined,
    refreshToken: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN) || undefined,
    reset: () => {
        localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
        localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
        set({user: null, accessToken: undefined, refreshToken: undefined})
    },
    setAuthData: data => {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, data.accessToken)
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, data.refreshToken)
        set({user: data.user, accessToken: data.accessToken, refreshToken: data.refreshToken})
    },
    setUser: data => set({user: data})
}))
