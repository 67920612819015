import {FormProvider, SubmitHandler, useForm} from 'react-hook-form'
import {zodResolver} from '@hookform/resolvers/zod'
import {
    CreateUserFormSchema,
    CreateUserValidationSchema
} from '@/features/users/components/create-user-form/CreateUserFormModel.ts'
import React, {useState} from 'react'
import {DataUserForm} from '@/features/users/components/create-user-form/data-userForm/DataUserForm.tsx'
import {PermissionsUserForm} from '@/features/users/components/create-user-form/permission-user-form/PermissionsUserForm.tsx'
import {useGetPermissions} from '@/features/users/services/query/useGetPermissions.ts'
import {adaptPermissionsToSelect, adaptUserToCreate} from '@/features/users/utils.ts'
import {useTranslation} from 'react-i18next'
import toast from 'react-hot-toast'
import {useUpdateUser} from '@/features/users/services/query/useUpdateUser.ts'
import {
    StyledChangeStepContainer,
    StyledUpdateUserModalFooter
} from '@/features/users/components/update-user-form/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {TabButton} from '@components/ui/tab-button/TabButton.tsx'

interface CreateUserFormProps {
    onCloseModal: () => void
    userId: number
    defaultValues: CreateUserValidationSchema
}
export const UpdateUserForm: React.FC<CreateUserFormProps> = ({onCloseModal, userId, defaultValues}) => {
    const {t} = useTranslation()
    const methods = useForm<CreateUserValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(CreateUserFormSchema),
        defaultValues: defaultValues
    })
    const {remappedData} = useGetPermissions()
    const {mutate: updateUser, isPending: isPendingUpdate} = useUpdateUser({
        onSuccess: () => {
            toast.success(t('users:updateModal:success'))
            onCloseModal()
        }
    })

    const [formStep, setFormStep] = useState<'data' | 'permissions'>('data')
    const onClickPermissions = () => {
        setFormStep('permissions')
    }
    const onClickData = () => {
        setFormStep('data')
    }
    const onSubmit: SubmitHandler<CreateUserValidationSchema> = data => {
        updateUser({data: adaptUserToCreate(data), userId: userId})
    }
    return (
        <FormProvider {...methods}>
            <StyledChangeStepContainer>
                <TabButton onClick={onClickData} $isActive={formStep === 'data'}>
                    <p>{t('users:updateModal:data')}</p>
                </TabButton>
                <TabButton onClick={onClickPermissions} $isActive={formStep === 'permissions'}>
                    <p>{t('users:updateModal:permissions')}</p>
                </TabButton>
            </StyledChangeStepContainer>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                {formStep == 'data' && <DataUserForm isUpdate />}
                {formStep == 'permissions' && (
                    <PermissionsUserForm isUpdate permissions={adaptPermissionsToSelect(remappedData)} />
                )}
                <StyledUpdateUserModalFooter justify={'space-between'}>
                    <Button variant={'ghost'} onClick={onCloseModal}>
                        {t('users:updateModal:secondary_cta')}
                    </Button>
                    <Button
                        variant={'primary'}
                        type={'submit'}
                        disabled={isPendingUpdate || !methods.formState.isValid || !methods.formState.isDirty}
                    >
                        {t('users:updateModal:primary_cta')}
                        {isPendingUpdate && <Spinner size={20} />}
                    </Button>
                </StyledUpdateUserModalFooter>
            </form>
        </FormProvider>
    )
}
