import {CSSProperties} from 'react'
import {spacing} from '@/theme/theme.ts'
import styled, {css} from 'styled-components'

export const toastBarStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: spacing,
    padding: spacing * 3,
    minWidth: 'fit-content'
} as const satisfies CSSProperties

export const ToastContentWrapper = styled.div`
    ${({theme: {typography, spacing}}) => css`
        & h4 {
            font-weight: 600;
            ${typography.textSm}
        }
        & p {
            font-weight: 400;
            margin-top: ${spacing}px;
            ${typography.textSm}
        }
    `}
`
