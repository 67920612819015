import {
    StyleButtonAttachmentLink,
    StyledAttachmentContainer,
    StyledFileInfoContainer
} from '@/features/tickets/components/attachment/style.ts'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'

interface AttachmentProps {
    fileName: string
    extension: string
    url: string
}
export const Attachment: FC<AttachmentProps> = ({fileName, extension, url, ...rest}) => {
    const {t} = useTranslation()
    const absoluteUrl = new URL(url).href
    return (
        <StyledAttachmentContainer justify={'space-between'} {...rest}>
            <StyledFileInfoContainer>
                <h4>{fileName}</h4>
                <p>{extension.toUpperCase()}</p>
            </StyledFileInfoContainer>

            <StyleButtonAttachmentLink href={absoluteUrl} target={'_blank'}>
                {t('commons:open')}
            </StyleButtonAttachmentLink>
        </StyledAttachmentContainer>
    )
}
