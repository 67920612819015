import React, {useState} from 'react'
import {
    StyledAside,
    StyledAsideBody,
    StyledAsideHeader,
    StyledAsideOverlay,
    StyledAsideTabContainer
} from '@/features/tickets/components/tickets-aside/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {TabButton} from '@components/ui/tab-button/TabButton.tsx'
import {useTranslation} from 'react-i18next'
import {Attachment} from '@/features/tickets/components/attachment/Attachment.tsx'
import {MessagesList} from '@/features/conversation/components/messages-list/MessagesList.tsx'
import {useGetTicketsDetails} from '@/features/tickets/services/query/useGetTicketsDetails.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

interface AsideProps {
    ticketId: number
    onClickOverlay?: () => void
    onCloseAside: () => void
}

export const ConversationsTicketsAside: React.FC<AsideProps> = ({onClickOverlay, onCloseAside, ticketId}) => {
    const {t} = useTranslation()
    const {data: ticket, isLoading: isLoadingTicket} = useGetTicketsDetails({
        ticketId: ticketId,
        responseType: 'regular'
    })
    const [asideSection, setAsideSection] = useState<'report' | 'chat' | 'delegation'>('report')

    return (
        <>
            <StyledAsideOverlay onClick={onClickOverlay} />
            <StyledAside>
                <StyledAsideHeader justify={'space-between'} align={'center'}>
                    <Flexbox align={'center'} gap={2.5}>
                        <Button size={'sm'} onClick={onCloseAside} shape={'square'} variant={'ghost'}>
                            <XCloseIcon />
                        </Button>
                        <h4>{ticket?.id}</h4>
                    </Flexbox>
                </StyledAsideHeader>
                {!ticket || isLoadingTicket ? (
                    <>
                        <StyledAsideTabContainer>
                            <Flexbox gap={2}>
                                <Skeleton width={130} height={36} />
                                <Skeleton width={70} height={36} />
                            </Flexbox>
                        </StyledAsideTabContainer>
                        <StyledAsideBody>
                            <Skeleton height={'50%'} />
                        </StyledAsideBody>
                    </>
                ) : (
                    <>
                        <StyledAsideTabContainer>
                            <TabButton onClick={() => setAsideSection('report')} $isActive={asideSection === 'report'}>
                                <p>{t('tickets:aside:labels:report')}</p>
                            </TabButton>
                            <TabButton onClick={() => setAsideSection('chat')} $isActive={asideSection === 'chat'}>
                                <p>{t('tickets:aside:labels:chat')}</p>
                            </TabButton>
                            {ticket?.attachments.length !== 0 && (
                                <TabButton
                                    onClick={() => setAsideSection('delegation')}
                                    $isActive={asideSection === 'delegation'}
                                >
                                    <p>{t('tickets:aside:labels:delegation')}</p>
                                </TabButton>
                            )}
                        </StyledAsideTabContainer>
                        <StyledAsideBody>
                            {asideSection === 'report' && <p>{ticket?.content}</p>}
                            {asideSection === 'chat' && <MessagesList conversationId={ticket.conversationId} />}
                            {asideSection === 'delegation' && ticket?.attachments.length !== 0 && (
                                <Flexbox gap={3} direction={'column'} align={'stretch'}>
                                    {ticket?.attachments?.map((attachment, key) => (
                                        <Attachment
                                            key={key}
                                            fileName={t(`tickets:aside:attachment:${attachment.type}`)}
                                            extension={attachment.extension}
                                            url={attachment.url}
                                        />
                                    ))}
                                </Flexbox>
                            )}
                        </StyledAsideBody>
                    </>
                )}
            </StyledAside>
        </>
    )
}
