import {TableProps} from '@components/commons/table/Table.tsx'
import {StyledDesktopTable} from '@/components/commons/table/desktop-table/style'
import {DesktopTHead} from '@/components/commons/table/desktop-t-head/DesktopTHead'
import {DesktopTBody} from '@/components/commons/table/desktop-t-body/DesktopTBody'

export const DesktopTable = <TData extends unknown[], TSortName extends string | undefined>({
    columns,
    sorter,
    data,
    onChangePage,
    isError,
    isChangingPage,
    isLoading,
    subRow,
    selectedRowIndex,
    onClickRow,
    expandedRows
}: TableProps<TData, TSortName>) => {
    return (
        <StyledDesktopTable>
            <DesktopTHead columns={columns} sorter={sorter} />
            <DesktopTBody
                selectedRowIndex={selectedRowIndex}
                data={data}
                columns={columns}
                onChangePage={onChangePage}
                isError={isError}
                isChangingPage={isChangingPage}
                isLoading={isLoading}
                subRow={subRow}
                expandedRows={expandedRows}
                onClickRow={onClickRow}
            />
        </StyledDesktopTable>
    )
}

DesktopTable.displayName = 'DesktopTable'
