import dayjs from '@/dayjs.ts'
import i18n from 'i18next'

export const ticketFormatData = (data: string) => {
    const now = dayjs()
    const diffInSeconds = now.diff(data, 'second')

    switch (true) {
        case diffInSeconds < 60:
            return i18n.t('tickets:table:date:few_second')
        case diffInSeconds < 3600:
            return i18n.t('tickets:table:date:few_minute')
        case diffInSeconds < 86400:
            return i18n.t('tickets:table:date:few_hours')
        case diffInSeconds < 172800:
            return i18n.t('tickets:table:date:two_days')
        default:
            return dayjs(data).format('DD MMM YYYY, HH:mm')
    }
}
