import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetTicketDetails} from '@/features/tickets/services/tickets.http.ts'
import {HttpGetTicketDetailsParams, Ticket} from '@/features/tickets/types.ts'

export const useGetTicketsDetails = (params: HttpGetTicketDetailsParams) => {
    return useQuery({
        queryKey: [QUERY_KEYS.TICKETS, params.ticketId],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetTicketDetails(params),
                onZodError: captureException,
                responseShape: Ticket
            })
    })
}
