import {Outlet} from 'react-router-dom'
import {StyledPrivateLayout} from './styles'
import React from 'react'
import {Header} from '@components/commons/header/Header.tsx'
import {getPermissionsArray} from '@/features/auth/helpers.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {getPermitName} from '@/features/users/utils.ts'

//TODO: items array should be removed and replaced by paths rendered based on the user permissions
type PrivateLayoutProps = {
    withHeader?: boolean
}
export const PrivateLayout: React.FC<PrivateLayoutProps> = ({withHeader}) => {
    const {user} = useAuthStore()

    const ITEMS = getPermissionsArray(getPermitName(user?.permissions))

    return (
        <StyledPrivateLayout>
            {withHeader && <Header items={ITEMS} />}
            <Outlet />
        </StyledPrivateLayout>
    )
}
