import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCardWrapper = styled.div(
    ({theme: {palette}}) => css`
        width: 100%;
        height: 100%;
        background-color: ${palette.neutral['white']};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 12px;
    `
)
export const StyledCardContainer = styled.div(
    ({theme: {palette, typography, spacing}}) => css`
        padding: ${spacing * 6}px;
        & p {
            ${typography.textSm}
        }
        & h4 {
            font-weight: 600;
            color: ${palette.neutral['900']};
            ${typography.displayMd}
        }
    `
)

export const StyledPercentage = styled(Flexbox)<{$isPositive: boolean}>(
    ({theme: {palette}, $isPositive}) => css`
        color: ${$isPositive ? palette.success['600'] : palette.danger['600']};
    `
)
export const StyledPercentageContainer = styled(Flexbox)`
    width: 100%;
`
