import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'

export const StyledDashboardTicketSectionGrid = styled.div(
    ({theme: {spacing}}) => css`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 2fr;
        gap: ${spacing * 6}px;
    `
)

export const StyledDashboardMetricsSectionGrid = styled.div(
    ({theme: {spacing}}) => css`
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: ${spacing * 6}px;
    `
)

export const StyledCardFooter = styled(Flexbox)(
    ({theme: {palette, spacing}}) => css`
        width: 100%;
        border-top: 1px solid ${palette.neutral['200']};
        padding: ${spacing * 2}px;
    `
)

export const StyledSkeleton = styled(Skeleton)`
    flex-shrink: initial;
`
