import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {StyledAuthBoxWrapper, StyledAuthWrapper} from '@pages/login/style.ts'
import {useTranslation} from 'react-i18next'
import Logo from '@assets/images/logo_icon.svg'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {LoginForm} from '@/features/auth/components/login-form/LoginForm.tsx'
import {Divider} from '@components/ui/divider/Divider.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {MessageSmileSquareIcon} from '@components/ui/icon/Icon.tsx'
import {envVars} from '@/envVars.ts'
export const Login: React.FC = () => {
    const {t} = useTranslation()
    const onClickAiChat = () => {
        window.location.href = import.meta.env.VITE_API_ENDPOINT.includes('k-stage')
            ? envVars.VITE_AI_STAGING_ENDPOINT
            : envVars.VITE_AI_ENDPOINT
    }

    return (
        <Container>
            <StyledAuthWrapper>
                <StyledAuthBoxWrapper>
                    <Flexbox justify={'center'}>
                        <img src={Logo} alt={'logo'} width={48} />
                    </Flexbox>
                    <Flexbox direction={'column'} gap={2} align={'center'}>
                        <h4>{t('login:title')}</h4>
                        <p>{t('login:subtitle')}</p>
                    </Flexbox>
                    <LoginForm />
                    <Flexbox gap={2} align={'center'}>
                        <Divider direction={'horizontal'} />
                        <p>{t('login:or')}</p>
                        <Divider direction={'horizontal'} />
                    </Flexbox>
                    <Button onClick={onClickAiChat} variant={'secondary'} fullWidth>
                        <MessageSmileSquareIcon />
                        {t('login:ai_assistant')}
                    </Button>
                </StyledAuthBoxWrapper>
            </StyledAuthWrapper>
        </Container>
    )
}

Login.displayName = 'Login'
