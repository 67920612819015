import axios from '../../../axiosInstance.ts'
import {HttpGetTicketDetailsParams, HttpGetTicketsParams, HttpUpdateTicketParams} from '@/features/tickets/types.ts'
import {HttpExportParams} from '@/types/commons.ts'

/**
 * httpGetTickets
 * Here we will fetch all the tickets data for the dash table
 */

export const httpGetTickets = async (params: HttpGetTicketsParams) => {
    return await axios.get(`/tickets`, {params})
}

/**
 * httpExportTickets
 * Here we will export tickets
 */

export const httpExportTickets = async (params: HttpExportParams) => {
    return await axios.get(`/tickets/export`, {params})
}

/**
 * httpGetTicketDetails
 * Here we will fetch detail ticket data
 */

export const httpGetTicketDetails = async (params: HttpGetTicketDetailsParams) => {
    return await axios.get(`/tickets/${params.ticketId}`, {params})
}

/**
 * httpUpdateTicket
 * Here we will update ticket data
 */

export const httpUpdateTicket = async (params: HttpUpdateTicketParams) => {
    return await axios.patch(`/tickets/${params.ticketId}`, params.data)
}
