import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledMessageWrapper = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        width: 100%;
        ${typography.textXs}
        & span {
            ${typography.textSm}
        }
        & p {
            color: ${palette.neutral['900']};
            ${typography.textMd}
            font-weight: 400;
        }
    `}
`
export const StyledMessageContent = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        width: 100%;
        padding: ${spacing}px;
    `}
`
