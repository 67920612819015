import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {HttpUpdateTicketParams} from '@/features/tickets/types.ts'
import {httpUpdateTicket} from '@/features/tickets/services/tickets.http.ts'

type useUpdateTicketProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useUpdateTicket = (options?: useUpdateTicketProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_TICKET],
        mutationFn: (params: HttpUpdateTicketParams) => httpUpdateTicket(params),
        onError: (error: AxiosError<ErrorResponseData>) => {
            errorHandler(error)
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.TICKETS]})
            options?.onSuccess?.()
        }
    })
}
