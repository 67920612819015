import {HttpCreateUserBody, Permission, User} from '@/features/users/types.ts'
import {CreateUserValidationSchema} from '@/features/users/components/create-user-form/CreateUserFormModel.ts'
import i18n from '@/translations/i18n.ts'

export const adaptPermissionsToSelect = (permissions: Permission[]) => {
    return permissions.map(permit => {
        return {
            value: permit.id.toString(),
            label: i18n.t('users:table:' + permit.name)
        }
    })
}

export const adaptUserToCreate = (data: CreateUserValidationSchema): HttpCreateUserBody => {
    return {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        permissionsIds: data.permissions?.map(permit => parseInt(permit.value))
    }
}

export const adaptUserToForm = (data: User): CreateUserValidationSchema => {
    return {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        permissions: adaptPermissionsToSelect(data.permissions)
    }
}

export const getPermitName = (permissions: Permission[] | undefined): string[] => {
    return permissions ? permissions.map(permit => permit.name) : []
}
