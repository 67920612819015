import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {httpForgotPassword} from '@/features/auth/services/auth.http.ts'
import {ErrorResponseData} from '@/types/commons.ts'
import {ForgotPasswordValidationSchema} from '@/features/auth/components/forgot-password-form/ForgotPasswordFormModel.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'

type useForgotPasswordProps = {
    onSuccess?: () => void
}

export const useForgotPassword = (options?: useForgotPasswordProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.FORGOT_PASSWORD],
        mutationFn: (params: ForgotPasswordValidationSchema) => httpForgotPassword(params),
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error),
        onSuccess: () => options?.onSuccess?.()
    })
}
