export const palette = {
    light: {
        neutral: {
            white: '#FFFFFF',
            25: '#FCFCFD',
            50: '#F8FAFC',
            100: '#EEF2F6',
            200: '#E3E8EF',
            300: '#CDD5DF',
            400: '#9AA4B2',
            500: '#697586',
            600: '#4B5565',
            700: '#364152',
            800: '#202939',
            900: '#121926',
            950: '#0D121C',
            black: '#000000'
        },
        primary: {
            25: '#F5FBFF',
            50: '#F0F9FF',
            100: '#E0F2FE',
            200: '#B9E6FE',
            300: '#7CD4FD',
            400: '#36BFFA',
            500: '#0BA5EC',
            600: '#0086C9',
            700: '#026AA2',
            800: '#065986',
            900: '#0B4A6F',
            950: '#062C41'
        },
        secondary: {
            25: '#F2F6FD',
            50: '#B2B1B7',
            100: '#8A909E',
            200: '#6A707E',
            300: '#464A53',
            400: '#8A909E',
            500: '#6A707E',
            600: '#464A53',
            700: '#8A909E',
            800: '#6A707E',
            900: '#464A53',
            950: '#102A56'
        },
        success: {
            25: '#F6FEF9',
            50: '#ECFDF3',
            100: '#DCFAE6',
            200: '#ABEFC6',
            300: '#75E0A7',
            400: '#47CD89',
            500: '#17B26A',
            600: '#079455',
            700: '#067647',
            800: '#085D3A',
            900: '#074D31',
            950: '#053321'
        },
        danger: {
            25: '#FFFBFA',
            50: '#FEF3F2',
            100: '#FEE4E2',
            200: '#FECDCA',
            300: '#FDA29B',
            400: '#F97066',
            500: '#F04438',
            600: '#D92D20',
            700: '#B42318',
            800: '#912018',
            900: '#7A271A',
            950: '#55160C'
        },
        warning: {
            25: '#FFFCF5',
            50: '#FFFAEB',
            100: '#FEF0C7',
            200: '#FEDF89',
            300: '#FEC84B',
            400: '#FDB022',
            500: '#F79009',
            600: '#DC6803',
            700: '#B54708',
            800: '#93370D',
            900: '#7A2E0E',
            950: '#4E1D09'
        }
    }
    //todo: we will support the dark mode documenting this one and adding the dark types to our style.d.ts
    /*dark: null*/
}
