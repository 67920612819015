import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts'
import {FC} from 'react'
import {GraphicData, HttpDashboardDataParams} from '@/features/dashboard/types.ts'
import {GraphicChartContainer} from '@/features/dashboard/graphic-chart/style.ts'
import {remappedGraphicsData} from '@/features/dashboard/utils.ts'
import {useTranslation} from 'react-i18next'
import {findDateRangeKey} from '@components/commons/date-filter-select/utils.ts'

interface GraphicChartProps {
    data: GraphicData[]
    searchParams: HttpDashboardDataParams
}
export const GraphicChart: FC<GraphicChartProps> = ({data, searchParams}) => {
    const {t} = useTranslation()

    const remappedData =
        findDateRangeKey(searchParams) === 'last_year' || findDateRangeKey(searchParams) === 'this_year'
            ? remappedGraphicsData(data)
            : data
    return (
        <GraphicChartContainer>
            <ResponsiveContainer width={'100%'}>
                <LineChart data={remappedData}>
                    <CartesianGrid vertical={false} />
                    <XAxis strokeWidth={0} dataKey="name" />
                    <YAxis strokeWidth={0} />
                    <Legend verticalAlign={'top'} align={'right'} iconType={'circle'} />
                    <Line
                        dot={false}
                        type={'monotone'}
                        dataKey="conversations"
                        name={t('dashboard:conversations')}
                        stroke="rgba(21, 112, 239, 1)"
                        strokeWidth={3}
                    />
                    <Tooltip />
                    <Line
                        dot={false}
                        type={'monotone'}
                        dataKey="tickets"
                        name={t('dashboard:tickets')}
                        stroke="rgba(230, 46, 5, 1)"
                        strokeWidth={3}
                    />
                </LineChart>
            </ResponsiveContainer>
        </GraphicChartContainer>
    )
}
