import {FC, PropsWithChildren, ReactNode} from 'react'

import {StyledPageContainer} from './style'
import {PageHero} from '@components/commons/page-hero/PageHero.tsx'

interface PageLayoutProps extends PropsWithChildren {
    title: string
    subtitle?: string | null
    sideHeading?: ReactNode
    className?: string
}

export const PageLayout: FC<PageLayoutProps> = ({title, subtitle, sideHeading, children, className}) => {
    return (
        <StyledPageContainer className={className} fullWidth={true}>
            <PageHero title={title} subtitle={subtitle} sideComponent={sideHeading} />
            {children}
        </StyledPageContainer>
    )
}
