import {z} from 'zod'
import {User} from '@/features/users/types.ts'

const AuthState = z.object({
    user: User.nullable(),
    accessToken: z.string(),
    refreshToken: z.string()
})
export type AuthState = z.infer<typeof AuthState>

export const HttpLoginResponse = z.object({
    user: User,
    accessToken: z.string(),
    refreshToken: z.string()
})
export type HttpLoginResponse = z.infer<typeof HttpLoginResponse>

export const HttpChangePasswordBody = z.object({
    token: z.string(),
    password: z.string(),
    passwordConfirmation: z.string(),
    isFirstLogin: z.boolean()
})
export type HttpChangePasswordBody = z.infer<typeof HttpChangePasswordBody>
