import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledUpdateUserModalContent = styled.div`
    ${({theme: {typography, palette}}) => css`
        min-height: 400px;
        & h4 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textMd};
            color: ${palette.neutral['900']};
        }
    `}
`
export const StyledUpdateUserModalHeader = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `}
`

export const StyledUpdateUserModalCloseButton = styled(Button)`
    ${() => css`
        position: absolute;
        left: 8px;
        top: 10px;
    `}
`
