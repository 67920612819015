import * as pages from '@/pages'
import HomeRedirect from '@route-guards/home-route/HomeRedirect.tsx'

export const routes = {
    LOGIN: {
        path: '/login',
        element: <pages.Login />
    },
    FORGOT_PASSWORD: {
        path: '/forgot-password',
        element: <pages.ForgotPassword />
    },
    EMAIL_SENT: {
        path: '/email-sent',
        element: <pages.EmailSent />
    },
    CHANGE_PASSWORD: {
        path: '/change-password/:token',
        element: <pages.ChangePassword />
    },
    SET_PASSWORD: {
        path: '/set-password/:token',
        element: <pages.SetPassword />
    },
    HOME: {
        path: '/',
        element: <HomeRedirect />,
        handle: {
            path: '/',
            paramsName: [],
            name: 'routeNames:home',
            end: true
        }
    },
    USERS: {
        path: '/users',
        element: <pages.Users />
    },
    CONVERSATION_HISTORY: {
        path: '/chats',
        element: <pages.ConversationHistory />
    },
    TICKETS: {
        path: '/tickets',
        element: <pages.Tickets />
    },
    DASHBOARD: {
        path: '/dashboard',
        element: <pages.Dashboard />
    },
    NOT_FOUND: {
        path: '*',
        element: <pages.NotFound />
    }
}
