import styled, {css} from 'styled-components'
import {PageLayout} from '@layouts/page-layout/PageLayout.tsx'

export const DashboardPageLayout = styled(PageLayout)(
    ({theme: {palette, typography}}) => css`
        background-color: ${palette.neutral[50]};
        overflow-y: scroll;
        & h4 {
            font-weight: 600;
            color: ${palette.neutral['900']};
            ${typography.textLg}
        }
    `
)
