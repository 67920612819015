import React, {useEffect, useState} from 'react'
import {
    StyledAside,
    StyledAsideBody,
    StyledAsideHeader,
    StyledAsideTabContainer
} from '@/features/tickets/components/tickets-aside/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {CheckIcon, XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {TabButton} from '@components/ui/tab-button/TabButton.tsx'
import {useTranslation} from 'react-i18next'
import {useTicketsTable} from '@/features/tickets/services/query/useTicketsTable.ts'
import {Attachment} from '@/features/tickets/components/attachment/Attachment.tsx'
import {MessagesList} from '@/features/conversation/components/messages-list/MessagesList.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {containsAtLeastOne} from '@utilities/helpers.ts'
import {getPermitName} from '@/features/users/utils.ts'
import {useUpdateTicket} from '@/features/tickets/services/query/useUpdateTicket.ts'
import toast from 'react-hot-toast'
import dayjs from 'dayjs'

interface AsideProps {
    currentTicketIndex: number
    onClickOverlay?: () => void
    onCloseAside: () => void
    setCurrentTicketIndex: React.Dispatch<number>
    currentSection: 'open' | 'closed'
}

export const TicketsAside: React.FC<AsideProps> = ({
    currentTicketIndex,
    onCloseAside,
    currentSection,
    setCurrentTicketIndex
}) => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const {remappedData: tickets, isPending: isPendingTickets} = useTicketsTable()
    const [asideSection, setAsideSection] = useState<'report' | 'chat' | 'delegation'>('report')
    const {mutate: updateTicket, isPending: pendingUpdate} = useUpdateTicket({
        onSuccess: () =>
            currentSection === 'open'
                ? toast.success(t('tickets:ticket_to_closed'))
                : toast.success(t('tickets:ticket_to_open'))
    })

    const oncClickMoveToClosed = (ticketId: number) => {
        if (user?.id) {
            updateTicket({ticketId, data: {completedBy: user.id, completedAt: dayjs().toISOString()}})
        }
        onCloseAside()
    }
    const oncClickMoveToOpen = (ticketId: number) => {
        updateTicket({ticketId, data: {completedBy: null, completedAt: null}})
        onCloseAside()
    }
    const handleKeyDown = (event: KeyboardEvent) => {
        if (isPendingTickets) {
            return null
        }
        if (event.key === 'ArrowUp' && currentTicketIndex !== 0) {
            setCurrentTicketIndex((currentTicketIndex -= 1))
        } else if (event.key === 'ArrowDown' && tickets.length - 1 !== currentTicketIndex) {
            setCurrentTicketIndex((currentTicketIndex += 1))
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])
    useEffect(() => {
        if (tickets[currentTicketIndex].attachments.length === 0) {
            setAsideSection('chat')
        }
    }, [currentTicketIndex])
    return (
        <>
            <StyledAside>
                <StyledAsideHeader justify={'space-between'} align={'center'}>
                    <Flexbox align={'center'} gap={2.5}>
                        <Button size={'sm'} onClick={onCloseAside} shape={'square'} variant={'ghost'}>
                            <XCloseIcon />
                        </Button>
                        <h4>#{tickets[currentTicketIndex].id}</h4>
                    </Flexbox>
                    {currentSection === 'open' ? (
                        <Button
                            variant={'secondary'}
                            disabled={pendingUpdate}
                            onClick={() => oncClickMoveToClosed(tickets[currentTicketIndex].id)}
                        >
                            <CheckIcon />
                            {t('tickets:table:mark_closed')}
                        </Button>
                    ) : (
                        <Button
                            variant={'tertiary'}
                            disabled={pendingUpdate}
                            onClick={() => oncClickMoveToOpen(tickets[currentTicketIndex].id)}
                        >
                            {t('tickets:table:mark_open')}
                        </Button>
                    )}
                </StyledAsideHeader>
                <StyledAsideTabContainer>
                    <TabButton onClick={() => setAsideSection('report')} $isActive={asideSection === 'report'}>
                        <p>{t('tickets:aside:labels:report')}</p>
                    </TabButton>
                    {user && containsAtLeastOne(['conversation'], getPermitName(user.permissions)) && (
                        <TabButton onClick={() => setAsideSection('chat')} $isActive={asideSection === 'chat'}>
                            <p>{t('tickets:aside:labels:chat')}</p>
                        </TabButton>
                    )}
                    {tickets[currentTicketIndex].attachments.length !== 0 && (
                        <TabButton
                            onClick={() => setAsideSection('delegation')}
                            $isActive={asideSection === 'delegation'}
                        >
                            <p>{t('tickets:aside:labels:delegation')}</p>
                        </TabButton>
                    )}
                </StyledAsideTabContainer>
                <StyledAsideBody>
                    {asideSection === 'report' && <p>{tickets[currentTicketIndex].content}</p>}
                    {asideSection === 'chat' && (
                        <MessagesList conversationId={tickets[currentTicketIndex].conversationId} />
                    )}
                    {asideSection === 'delegation' && tickets[currentTicketIndex].attachments.length !== 0 && (
                        <Flexbox gap={3} direction={'column'} align={'stretch'}>
                            {tickets[currentTicketIndex].attachments?.map((attachment, key) => (
                                <Attachment
                                    key={key}
                                    fileName={t(`tickets:aside:attachment:${attachment.type}`)}
                                    extension={attachment.extension}
                                    url={attachment.url}
                                />
                            ))}
                        </Flexbox>
                    )}
                </StyledAsideBody>
            </StyledAside>
        </>
    )
}
