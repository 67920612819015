import styled, {css} from 'styled-components'

type StyledContainerProps = {
    $fullWidth: boolean
    $fullHeight: boolean
}

export const StyledContainer = styled.div<StyledContainerProps>`
    ${({$fullWidth, $fullHeight, theme: {spacing, mediaQueries}}) => css`
        width: 100%;
        height: ${$fullHeight ? '100%' : 'auto'};
        padding: 0 ${spacing * 20}px;
        max-width: ${$fullWidth ? '100%' : '1280px'};
        margin: 0 auto;
        ${mediaQueries.l} {
            padding: 0 ${spacing * 4}px;
        }
    `}
`
