import {z} from 'zod'

export const CHANGE_PASSWORD_FORM_MODEL = {
    password: {
        name: 'password',
        label: 'changePassword:form:password',
        placeholder: '',
        defaultValue: '',
        helpText: ''
    },
    confirmPassword: {
        name: 'confirmPassword',
        label: 'changePassword:form:confirm_password',
        placeholder: '',
        defaultValue: '',
        helpText: ''
    }
} as const

export const ChangePasswordFormSchema = z
    .object({
        password: z.string().min(6, {message: 'errors:password'}),
        confirmPassword: z.string().min(1, {message: 'errors:confirm_password'}).min(6, {message: 'errors:password'})
    })
    .refine(data => data.password === data.confirmPassword, {
        message: 'errors:password_match',
        path: ['confirmPassword']
    })

export type ChangePasswordValidationSchema = z.infer<typeof ChangePasswordFormSchema>
