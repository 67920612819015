import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledTicketPageHeaderContainer = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
`
export const StyledTicketPageHeaderWrapper = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding-top: ${spacing * 10}px;
    `}
`

export const StyleTabSection = styled.div`
    ${({theme: {palette}}) => css`
        border-bottom: 1px solid ${palette.neutral['300']};
    `}
`
