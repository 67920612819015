import {useParsedSearchParams} from '@/hooks/useParsedSearchParams'
import {ResponseType} from '@/types/commons'
import {useInputSearch} from '@/hooks/useInputSearch'
import {useEffect} from 'react'
import {useGetUsers} from '@/features/users/services/query/useGetUsers.ts'
import {HttpGetUsersSearchParams} from '@/features/users/types.ts'

export const useUsersTable = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof HttpGetUsersSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, HttpGetUsersSearchParams)
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch(searchParams.searchString)
    const ticketsQuery = useGetUsers({
        ...searchParams,
        limit: 20,
        responseType: ResponseType.enum.regular
    })

    useEffect(() => {
        if (searchValue) {
            setSearchParams({searchString: searchValue})
        } else {
            setSearchParams({searchString: undefined})
        }
    }, [searchValue])

    return {
        ...ticketsQuery,
        onSearch,
        onResetSearch,
        searchError,
        searchValue,
        filterValue: Object.values(searchParams)
            .filter(param => param !== 'searchString')
            .some(value => value !== null && value !== undefined)
    }
}
