import axios from '../../../axiosInstance.ts'
import {HttpGetMessagesParams} from '@/features/message/types.ts'

/**
 * httpGetMessages
 * Here we will recover all the messages of a conversation
 */

export const httpGetMessages = async (params: HttpGetMessagesParams) => {
    return await axios.get(`/conversations/${params.conversationId}/messages`, {params})
}
