import React, {useRef, useState} from 'react'
import {PageLayout} from '@layouts/page-layout/PageLayout.tsx'
import {useTranslation} from 'react-i18next'
import {UsersTable} from '@/features/users/components/users-table/UsersTable.tsx'
import {User} from '@/features/users/types.ts'
import {UserDeleteModal} from '@/features/users/components/user-delete-modal/UserDeleteModal.tsx'
import {UserCreateModal} from '@/features/users/components/user-create-modal/UserCreateModal.tsx'
import {UserUpdateModal} from '@/features/users/components/user-update-modal/UserUpdateModal.tsx'
import {ConfirmModal} from '@components/commons/confirm-modal/ConfirmModal.tsx'
import {useForgotPassword} from '@/features/auth/services/query/useForgotPassword.ts'
import toast from 'react-hot-toast'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {useUsersTable} from '@/features/users/services/query/useUsersTable.ts'
import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {StyledNewUserButton} from '@pages/users/style.ts'

export const Users: React.FC = () => {
    const {t} = useTranslation()
    const {
        remappedData: users,
        onSearch,
        searchValue,
        fetchNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
        onResetSearch
    } = useUsersTable()
    const [userToDelete, setUserToDelete] = useState<User | null>(null)
    const [userToUpdate, setUserToUpdate] = useState<User | null>(null)
    const [emailToForgotModal, setEmailToShowForgotModal] = useState<string | null>(null)
    const [showCreateUser, setShowCreateUser] = useState<boolean>(false)
    const searchRef = useRef<HTMLInputElement | null>(null)
    const {mutate: mutateForgotPassword, isPending: isPendingForgotPassword} = useForgotPassword({
        onSuccess: () => {
            toast.success(t('emailSent:title'))
            onCloseForgotModal()
        }
    })
    const onClickSendRecoveryEmail = (email: string) => {
        mutateForgotPassword({email: email})
    }

    const onCloseDeleteModal = () => {
        setUserToDelete(null)
    }
    const onCloseUpdateModal = () => {
        setUserToUpdate(null)
    }
    const toggleShowCreateModal = () => {
        setShowCreateUser(!showCreateUser)
    }
    const onCloseForgotModal = () => {
        setEmailToShowForgotModal(null)
    }

    return (
        <PageLayout
            title={t('users:title')}
            sideHeading={
                <Flexbox align={'center'} gap={3}>
                    <InputText
                        onChange={e => onSearch(e.currentTarget.value)}
                        defaultValue={searchValue}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                    />
                    <StyledNewUserButton onClick={toggleShowCreateModal} $variant={'primary'}>
                        {t('users:add_user')}
                    </StyledNewUserButton>
                </Flexbox>
            }
        >
            <UsersTable
                users={users}
                isChangingPage={isFetchingNextPage}
                isError={isError}
                isLoading={isLoading}
                onChangePage={fetchNextPage}
                setUserToDelete={setUserToDelete}
                setUserToUpdate={setUserToUpdate}
                setEmailToShowForgotModal={setEmailToShowForgotModal}
                onResetSearchCb={() => onResetSearch(searchRef)}
                searchValue={searchValue}
            />
            {userToDelete && <UserDeleteModal userToDelete={userToDelete} onCloseDeleteModal={onCloseDeleteModal} />}
            {showCreateUser && <UserCreateModal onCloseCreateModal={toggleShowCreateModal} />}
            {userToUpdate && <UserUpdateModal userToUpdate={userToUpdate} onCloseUpdateModal={onCloseUpdateModal} />}
            {emailToForgotModal && (
                <ConfirmModal
                    onClose={onCloseForgotModal}
                    modalTitle={t('users:forgotModal:modal_title')}
                    title={t('users:forgotModal:title', {email: emailToForgotModal})}
                    disablePrimaryCta={isPendingForgotPassword}
                    isPending={isPendingForgotPassword}
                    onClickCta={() => onClickSendRecoveryEmail(emailToForgotModal)}
                    primaryCtaLabel={t('users:forgotModal:primary_cta')}
                />
            )}
        </PageLayout>
    )
}

Users.displayName = 'Users'
