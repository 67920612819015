import styled, {css} from 'styled-components'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledTrashButton = styled(Button)`
    ${({theme: {palette}}) => css`
        svg {
            color: ${palette.danger['700']};
        }
    `}
`
