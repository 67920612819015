import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetDashboard} from '@/features/dashboard/services/dashboard.http.ts'
import {HttpDashboardData, HttpDashboardDataParams} from '@/features/dashboard/types.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'

export const useGetDashboardData = () => {
    const multiParams = [] as const satisfies ReadonlyArray<keyof HttpDashboardDataParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, HttpDashboardDataParams)
    const query = useQuery({
        queryKey: [QUERY_KEYS.DASHBOARD, searchParams.startDate, searchParams.endDate, searchParams.type],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetDashboard({...searchParams}),
                onZodError: captureException,
                responseShape: HttpDashboardData
            })
    })
    const remappedData = query?.data

    return {
        remappedData,
        setSearchParams,
        searchParams,
        ...query
    }
}
