import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {zodResolver} from '@hookform/resolvers/zod'
import {SubmitHandler, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {Button} from '@components/ui/button/Button.tsx'
import React from 'react'
import {
    CHANGE_PASSWORD_FORM_MODEL,
    ChangePasswordFormSchema,
    ChangePasswordValidationSchema
} from '@/features/auth/components/change-password-form/ChangePasswordFormModel.ts'
import {useChangePassword} from '@/features/auth/services/query/useChangePassword.ts'
import toast from 'react-hot-toast'
import {useNavigate, useParams} from 'react-router-dom'
import {CheckIcon} from '@components/ui/icon/Icon.tsx'
import {
    StyledChangePasswordIconContainer,
    StyledCorrespondWrapper
} from '@/features/auth/components/change-password-form/style.ts'
import {routes} from '@utilities/constants/routes.tsx'
import {ToastBody} from '@components/ui/toast-bar/ToastBody.tsx'

interface ChangePasswordFormProps {
    isFirstLogin?: boolean
}
export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({isFirstLogin = false}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {token} = useParams()

    const {mutate: changePasswordMutation, isPending: changePasswordIsFetching} = useChangePassword({
        onSuccess: () => {
            toast.success(() => (
                <ToastBody
                    title={t('changePassword:success:password_changed')}
                    subtitle={t('changePassword:success:access')}
                />
            ))
            navigate(routes.LOGIN.path)
        },
        onError: () => {
            toast.error(() => (
                <ToastBody title={t('changePassword:error:title')} subtitle={t('changePassword:error:subtitle')} />
            ))
            navigate(routes.FORGOT_PASSWORD.path)
        }
    })
    const {
        register,
        handleSubmit,
        formState: {errors, touchedFields, isValid}
    } = useForm<ChangePasswordValidationSchema>({
        mode: 'onBlur',
        resolver: zodResolver(ChangePasswordFormSchema)
    })

    const onSubmit: SubmitHandler<ChangePasswordValidationSchema> = data => {
        changePasswordMutation({
            password: data.password,
            passwordConfirmation: data.confirmPassword,
            token: token || '',
            isFirstLogin: isFirstLogin
        })
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flexbox direction={'column'} gap={6} align={'stretch'}>
                <InputText
                    label={t(CHANGE_PASSWORD_FORM_MODEL.password.label)}
                    type={'password'}
                    placeholder={t(CHANGE_PASSWORD_FORM_MODEL.password.label)}
                    errorMessage={t(errors.password?.message || '')}
                    {...register(t(CHANGE_PASSWORD_FORM_MODEL.password.name))}
                    visibilityToggle
                />
                <InputText
                    label={t(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.label)}
                    type={'password'}
                    placeholder={t(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.label)}
                    errorMessage={t(errors.confirmPassword?.message || '')}
                    {...register(t(CHANGE_PASSWORD_FORM_MODEL.confirmPassword.name))}
                    visibilityToggle
                />
                {!errors.confirmPassword && !!touchedFields.confirmPassword && (
                    <StyledCorrespondWrapper align={'center'} gap={4}>
                        <StyledChangePasswordIconContainer>
                            <CheckIcon size={16} />
                        </StyledChangePasswordIconContainer>
                        <p>{t('changePassword:form:correspond')}</p>
                    </StyledCorrespondWrapper>
                )}
                <Flexbox direction={'column'} gap={8} align={'stretch'}>
                    <Button
                        type={'submit'}
                        fullWidth
                        variant={'primary'}
                        disabled={changePasswordIsFetching || !isValid}
                    >
                        {t('changePassword:primary_cta')}
                    </Button>
                </Flexbox>
            </Flexbox>
        </form>
    )
}
