import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Button} from '@components/ui/button/Button.tsx'

export const StyledConversationSelectorContainer = styled.div(
    ({theme: {typography, palette}}) => css`
        display: grid;
        grid-template-columns: 1fr 2fr;
        & span {
            font-weight: 400;
            ${typography.textSm}
        }
        & h4 {
            font-weight: 500;
            color: ${palette.neutral['900']};
            ${typography.textMd}
        }
    `
)

export const StyledConversationListHeader = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3.5}px ${spacing * 4}px;
        border-right: 1px solid ${palette.neutral['300']};
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
export const StyledConversationListWrapper = styled.div(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 4}px 0 0;
        border-right: 1px solid ${palette.neutral['300']};
        height: 100%;
    `
)
export const StyledMessagesListHeader = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 3.5}px ${spacing * 4}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
export const StyledMessagesListWrapper = styled.div(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px;
        height: 100%;
    `
)
export const StyledConversationGrid = styled.div(
    () => css`
        display: grid;
        grid-template-rows: auto 1fr;
    `
)
export const StyledTickedIdButton = styled(Button)(
    ({theme: {palette}}) => css`
        color: ${palette.neutral['700']};
        padding: 0;
    `
)
