import {ButtonHTMLAttributes, forwardRef} from 'react'
import {StyledTabButton} from '@components/ui/tab-button/style.ts'

export type TabButtonStyleProps = {
    $fullWidth?: boolean
    $isActive: boolean
}

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & TabButtonStyleProps
export const TabButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
    <StyledTabButton ref={ref} {...props} />
))
