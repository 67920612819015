import axios from '../../../axiosInstance.ts'
import {HttpDashboardDataParams} from '@/features/dashboard/types.ts'

/**
 * httpGetDashboard
 * Here we will recover all the dashboard data
 */

export const httpGetDashboard = async (params?: HttpDashboardDataParams) => {
    return await axios.get(`conversations/stats`, {params})
}
