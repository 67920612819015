import {useQuery} from '@tanstack/react-query'
import {QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {httpGetPermissions} from '@/features/users/services/users.http.ts'
import {captureException} from '@sentry/react'
import {HttpGetPermissionsResponse} from '@/features/users/types.ts'

export const useGetPermissions = () => {
    const query = useQuery({
        queryKey: [QUERY_KEYS.PERMISSIONS],
        queryFn: () =>
            parseAxiosPromise({
                axiosPromise: httpGetPermissions({
                    limit: 100
                }),
                onZodError: captureException,
                responseShape: HttpGetPermissionsResponse
            })
    })
    const remappedData = query.data?.data || []
    return {
        remappedData,
        ...query
    }
}
