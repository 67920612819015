import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledEmptyTicketContainer = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        padding: ${spacing * 8}px 0;
        border: 1px solid ${palette.neutral[300]};
        height: fit-content;
        border-radius: 12px;
        & h4 {
            color: ${palette.neutral['900']};
            font-weight: 600;
            ${typography.textMd}
        }
        & p {
            ${typography.textSm}
        }
    `
)
