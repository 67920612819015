import styled, {css, DefaultTheme} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
export type AvatarRootProps = {
    $variant?: 'primary' | 'user' | 'assistant'
    $size?: 'sm' | 'md'
}
export const makeAvatarVariantStyle = (theme: DefaultTheme) =>
    ({
        primary: css`
            color: ${theme.palette.primary['700']};
            background: ${theme.palette.primary['100']};
            border: 1px solid ${theme.palette.primary['300']};
        `,
        user: css`
            color: ${theme.palette.neutral.white};
            background: ${theme.palette.neutral.white};
            border: 1px solid ${theme.palette.neutral['100']};
        `,
        assistant: css`
            color: ${theme.palette.primary['700']};
            background: ${theme.palette.primary['50']};
            border: 1px solid ${theme.palette.primary['100']};
        `
    }) as const satisfies Record<NonNullable<AvatarRootProps['$variant']>, ReturnType<typeof css>>

export const makeAvatarSizeStyle = (theme: DefaultTheme) =>
    ({
        sm: css`
            width: 32px;
            height: 32px;
            ${theme.typography.textSm};
            & svg {
                width: 20px;
                height: 20px;
            }
        `,
        md: css`
            width: 40px;
            height: 40px;
            font-weight: 600;
            ${theme.typography.textSm};
            & svg {
                width: 20px;
                height: 20px;
            }
        `
    }) as const satisfies Record<NonNullable<AvatarRootProps['$size']>, ReturnType<typeof css>>
export const AvatarRoot = styled(AvatarPrimitive.Root)<AvatarRootProps>`
    ${({theme, $variant, $size}) => css`
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
        user-select: none;
        border-radius: 100%;
        overflow: hidden;
        & img {
            width: 20px;
            height: 20px;
        }
        ${makeAvatarSizeStyle(theme)[$size ?? 'md']};
        ${makeAvatarVariantStyle(theme)[$variant ?? 'primary']};
    `}
`
export const AvatarImage = styled(AvatarPrimitive.Image)`
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: inherit;
`
export const AvatarFallback = styled(AvatarPrimitive.Fallback)(
    ({theme: {palette, typography}}) => css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${palette.neutral.white};
        color: ${palette.neutral['600']};
        ${typography.textMd};
        font-weight: 600;
    `
)
