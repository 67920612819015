import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledCreateUserFormWrapper = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        gap: ${spacing * 6}px;
        padding: ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `}
`
export const StyledInfo = styled.div`
    ${({theme: {spacing, palette, typography}}) => css`
        padding: ${spacing * 3}px;
        background-color: ${palette.neutral['100']};
        border-radius: 12px;
        & p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `}
`
export const StyledCreateUserModalFooter = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `}
`
export const StyledPermissionErrorBox = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 12px;
        border: 1px solid ${palette.neutral['300']};
        & p {
            font-weight: 600;
        }
        & > svg {
            color: ${palette.danger['600']};
        }
    `}
`
