import styled, {css} from 'styled-components'

export const StyledTableDataCell = styled.div`
    ${({theme: {typography, palette}}) => css`
        ${typography.textSm}
        & h4 {
            font-weight: 500;
            color: ${palette.neutral['900']};
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
        & p {
            font-weight: 400;
            color: ${palette.neutral['900']};
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
        & span {
            font-weight: 400;
            color: ${palette.neutral['600']};
            text-overflow: ellipsis;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
    `}
`
