import {FC} from 'react'
import {ConversationMessage} from '@/features/message/types.ts'
import AiAvatar from '@assets/images/logo_icon.svg'
import UserICon from '@assets/images/user_icon.svg'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {StyledMessageContent, StyledMessageWrapper} from '@components/ui/message/style.ts'
import {capitalize} from '@utilities/helpers.ts'
import {MarkdownText} from '@components/commons/markdown-text/MarkdownText.tsx'
import {ThumbsDownIcon, ThumbsUpIcon} from '@components/ui/icon/Icon.tsx'

interface MessageProps {
    message: ConversationMessage
    firstname: string | null
    lastname: string | null
}
export const Message: FC<MessageProps> = ({message, firstname, lastname}) => {
    const {t} = useTranslation()
    return (
        <StyledMessageWrapper gap={3}>
            <div>
                <Avatar
                    variant={message.systemRole === 'assistant' ? 'assistant' : 'user'}
                    name={'Avatar'}
                    imageUrl={message.systemRole === 'assistant' ? AiAvatar : UserICon}
                    size={'sm'}
                />
            </div>
            <StyledMessageContent direction={'column'} gap={2}>
                {message.systemRole === 'assistant' ? (
                    <span>{t('commons:title')}</span>
                ) : (
                    <span>
                        {firstname && lastname ? `${capitalize(firstname)} ${capitalize(lastname)}` : t('commons:user')}
                    </span>
                )}
                <MarkdownText content={message.message} />
                <div>
                    {message.isSatisfied != null ? (
                        message.isSatisfied ? (
                            <ThumbsUpIcon size={18} />
                        ) : (
                            <ThumbsDownIcon size={18} />
                        )
                    ) : null}
                </div>
            </StyledMessageContent>
            <div>{dayjs(message.createdAt).format('HH:mm')}</div>
        </StyledMessageWrapper>
    )
}
