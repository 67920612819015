import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetMessages} from '@/features/message/services/messages.http.ts'
import {HttpGetMessagesParams, HttpGetMessagesResponse} from '@/features/message/types.ts'

export const useGetMessages = (params: Omit<HttpGetMessagesParams, 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [QUERY_KEYS.MESSAGES, params.conversationId],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetMessages({
                    ...params,
                    page: pageParam
                }),
                onZodError: captureException,
                responseShape: HttpGetMessagesResponse
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
