import {MUTATION_KEYS} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {captureException} from '@sentry/react'
import {httpLogin} from '@/features/auth/services/auth.http.ts'
import {HttpLoginResponse} from '@/features/auth/types.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {LoginValidationSchema} from '@/features/auth/components/login-form/LoginFormModel.ts'
import {ErrorResponseData} from '@/types/commons.ts'

type useLoginProps = {
    onSuccess?: (data: HttpLoginResponse) => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useLogin = (options?: useLoginProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.LOGIN],
        mutationFn: (params: LoginValidationSchema) =>
            parseAxiosPromise({
                axiosPromise: httpLogin(params),
                onZodError: captureException,
                responseShape: HttpLoginResponse
            }),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: data => options?.onSuccess?.(data)
    })
}
