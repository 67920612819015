import styled, {css} from 'styled-components'

export const GraphicChartContainer = styled.div`
    ${({theme: {palette, spacing}}) => css`
        width: 100%;
        height: 100%;
        background-color: ${palette.neutral['white']};
        border: 1px solid ${palette.neutral['200']};
        border-radius: 12px;
        padding: ${spacing * 6}px;
    `}
`
