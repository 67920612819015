interface ToastBodyProps {
    title: string
    subtitle: string
}
export const ToastBody = ({title, subtitle}: ToastBodyProps) => {
    return (
        <div>
            <h4>{title}</h4>
            <p>{subtitle}</p>
        </div>
    )
}
