import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {errorHandler} from '@utilities/genericErrorHandler.ts'
import {httpUpdateUsers} from '@/features/users/services/users.http.ts'
import {HttpUpdateUserBody, User} from '@/features/users/types.ts'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'

type useUpdateUserProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useUpdateUser = (options?: useUpdateUserProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.UPDATE_USER],
        mutationFn: (params: HttpUpdateUserBody) =>
            parseAxiosPromise({
                axiosPromise: httpUpdateUsers(params),
                onZodError: captureException,
                responseShape: User
            }),
        onError: (error: AxiosError<ErrorResponseData>) => errorHandler(error),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.USERS]})
            options?.onSuccess?.()
        }
    })
}
