import {SelectValue} from '@components/commons/select/Select.tsx'
import i18n from '@/translations/i18n.ts'

export const selectUserTypeOption: SelectValue[] = [
    {
        label: i18n.t('commons:citizen'),
        value: 'citizen'
    },
    {
        label: i18n.t('commons:operator'),
        value: 'operator'
    }
]

export const adaptTypeSearchParamsToSelect = (params: string): SelectValue => {
    return {
        label: i18n.t(`commons:${params}`),
        value: params
    }
}
