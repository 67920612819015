import dayjs from '@/dayjs.ts'
import {GraphicData} from '@/features/dashboard/types.ts'
import i18n from '@/translations/i18n.ts'

export const dashboardLabelDate = (startDate?: string) => {
    const now = dayjs()
    switch (!!startDate) {
        case dayjs(startDate).diff(now, 'day') > -1:
            return 'ieri'
        case dayjs(startDate).diff(now, 'day') > -2:
            return "l'altro ieri"
        case dayjs(startDate).diff(now, 'week') > -1:
            return 'settimana scorsa'
        case dayjs(startDate).diff(now, 'week') > -2:
            return 'due settimane fa'
        case dayjs(startDate).diff(now, 'month') > -1:
            return 'mese scorso'
        case dayjs(startDate).diff(now, 'month') > -2:
            return 'due mesi fa'
        case dayjs(startDate).diff(now, 'year') > -1:
            return "l'anno scorso"
        case dayjs(startDate).diff(now, 'year') > -2:
            return 'due anni fa'
        default:
            return 'settimana scorsa'
    }
}

export const remappedGraphicsData = (data: GraphicData[]): GraphicData[] => {
    return data.map(value => ({
        name: i18n.t(`dashboard:graphic_data:${value.name}`),
        tickets: value.tickets,
        conversations: value.conversations
    }))
}
