import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledConversationItemWrapper = styled(Flexbox)<{$isActive: boolean}>(
    ({theme: {spacing, palette, typography}, $isActive}) => css`
        padding: ${spacing * 2}px ${spacing * 4}px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid transparent;
        margin-bottom: ${spacing * 2}px;
        ${$isActive &&
        css`
            background-color: ${palette.neutral['50']};
            border: 1px solid ${palette.neutral['200']};
        `}
        &:hover {
            background-color: ${palette.neutral['25']};
        }
        & h4 {
            font-weight: 500;
            color: ${palette.neutral['900']};
            ${typography.textSm}
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        & p {
            font-weight: 400;
            ${typography.textSm}
        }
    `
)

export const StyledTitleContainer = styled.div`
    overflow: hidden;
`
