import {z} from 'zod'

//const boolEnvVar = z.literal('true').or(z.literal('false')).transform(value => value == 'true')

export const envVars = z
    .object({
        VITE_APP_TITLE: z.string().optional(),
        VITE_API_ENDPOINT: z.string().url(),
        VITE_AI_STAGING_ENDPOINT: z.string().url(),
        VITE_AI_ENDPOINT: z.string().url()
    })
    .parse(import.meta.env)
