import {FC, InputHTMLAttributes, ReactElement} from 'react'
import {StyledChip} from './style'
import {DefaultNamespace} from 'i18next'

export interface ChipProps extends InputHTMLAttributes<HTMLDivElement> {
    id: string
    label: string | DefaultNamespace
    variant: 'success' | 'primary' | 'neutral'
    placeholder?: string
    icon?: ReactElement
    radiusFull?: boolean
}

export const Chip: FC<ChipProps> = ({id, radiusFull = false, label, variant = 'neutral', onClick, icon, ...rest}) => {
    return (
        <StyledChip id={id} $variant={variant} onClick={onClick} $radiusFull={radiusFull} {...rest}>
            {icon}
            <span>{label}</span>
        </StyledChip>
    )
}
