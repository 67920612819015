import {Navigate} from 'react-router-dom'
import {roleBasedRedirect} from '@/features/auth/helpers.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'

const HomeRedirect = () => {
    const {user, accessToken} = useAuthStore()

    return <Navigate to={roleBasedRedirect(accessToken, user?.permissions[0])} />
}

export default HomeRedirect
