import {z} from 'zod'
import {DateRangeParams} from '@/types/commons.ts'

export const DashboardCardData = z.object({
    isLastPeriodZero: z.boolean(),
    currentPeriod: z.number(),
    comparedToLastPeriodPercentage: z.number()
})
export type DashboardCardData = z.infer<typeof DashboardCardData>
export const DashboardDates = z.object({
    name: z.string(),
    tickets: z.number(),
    conversations: z.number()
})
export type DashboardDates = z.infer<typeof DashboardDates>
export const HttpDashboardData = z.object({
    chartStats: z.array(DashboardDates),
    openedTickets: DashboardCardData,
    openedConversations: DashboardCardData,
    averageConversationDuration: DashboardCardData,
    averageConversationMessages: DashboardCardData,
    fallbackPercentage: DashboardCardData,
    conversationsSatisfaction: DashboardCardData,
    messagesSatisfaction: DashboardCardData
})
export type HttpDashboardData = z.infer<typeof HttpDashboardData>

export const GraphicData = z.object({
    name: z.string(),
    tickets: z.number(),
    conversations: z.number()
})
export type GraphicData = z.infer<typeof GraphicData>
export const HttpDashboardDataParams = z.object({
    ...DateRangeParams.shape,
    type: z.string().optional()
})
export type HttpDashboardDataParams = z.infer<typeof HttpDashboardDataParams>
