import dayjs, {ConfigType} from 'dayjs'
import i18n from 'i18next'

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() == value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() == option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export const formatLocaleDate = (date: NonNullable<ConfigType>, formatType?: string) =>
    dayjs(date)
        .locale(i18n.language)
        .format(formatType || 'll')

export const containsAtLeastOne = <T>(master: T[] | readonly T[], sub: T[]): boolean => {
    return sub.some(r => master.indexOf(r) >= 0)
}

export const generateCsvDownload = ({data, fileName}: {data: string; fileName: string}) => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export function round(value: number, precision?: number) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
}
