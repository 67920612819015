import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {ConversationSelector} from '@/features/conversation/components/conversation-slector/ConversationSelector.tsx'
import {useGetConversation} from '@/features/conversation/services/query/useGetConversation.ts'
import {StyledPageLayoutConversationHystory} from '@pages/conversation-history/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledExportButton} from '@pages/tickets/style.ts'
import {GridIcon} from '@components/ui/icon/Icon.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import {generateCsvDownload} from '@utilities/helpers.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {palette} from '@/theme/palette.ts'
import {httpExportConversation} from '@/features/conversation/services/conversation.http.ts'
import {useParsedSearchParams} from '@hooks/useParsedSearchParams.ts'
import {SingleValue} from 'react-select'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {findDateRangeKey, getDateOptionByValue, selectDateValue} from '@components/commons/date-filter-select/utils.ts'
import {DateFilterSelect} from '@components/commons/date-filter-select/DateFilterSelect.tsx'
import {ConversationSearchParams} from '@/features/conversation/types.ts'
import {LoadingScreen} from '@components/commons/loading-screen/LoadingScreen.tsx'
import {ConversationsTicketsAside} from '@/features/tickets/components/tickets-aside/ConversationsTicketsAside.tsx'
import {UserTypeFilterSelect} from '@components/commons/user-type-filter-select/UserTypeFilterSelect.tsx'
import {adaptTypeSearchParamsToSelect} from '@components/commons/user-type-filter-select/utils.ts'

export const ConversationHistory: React.FC = () => {
    const {t} = useTranslation()
    const multiParams = [] as const satisfies ReadonlyArray<keyof ConversationSearchParams>
    const {searchParams, setSearchParams} = useParsedSearchParams(multiParams, ConversationSearchParams)
    const {
        remappedData: conversations,
        totalConversation,
        isLoading,
        isFetchingNextPage,
        fetchNextPage,
        isError
    } = useGetConversation({
        responseType: 'regular',
        limit: 50,
        orderBy: 'created_at',
        orderDirection: 'desc',
        ...searchParams
    })

    const [currentConversationIndex, setCurrentConversationIndex] = useState<number>(0)
    const [ticketIdToAside, setTicketIdToAside] = useState<number | null>(null)

    const {run: runExport, isLoading: isLoadingExport} = useAsync()

    const onClickExport = async () => {
        const exportData = await runExport(
            httpExportConversation({
                limit: conversations.length,
                page: 1,
                startDate: searchParams.startDate,
                endDate: searchParams.endDate
            })
        )
        generateCsvDownload({
            data: exportData.data,
            fileName: `Conversation${conversations[currentConversationIndex].id}`
        })
    }
    const onChangeDateSelect = (newValue: SingleValue<SelectValue>) => {
        if (newValue) {
            setSearchParams(selectDateValue[newValue.value])
        }
    }
    const onCloseAside = () => {
        setTicketIdToAside(null)
    }
    const onChangeTypeSelect = (newValue: SingleValue<SelectValue>) => {
        if (newValue) {
            setSearchParams({type: newValue.value})
        }
    }
    return (
        <StyledPageLayoutConversationHystory
            title={t('conversations:title')}
            sideHeading={
                <Flexbox align={'center'} gap={3}>
                    <UserTypeFilterSelect
                        defaultValue={searchParams.type ? adaptTypeSearchParamsToSelect(searchParams.type) : undefined}
                        onChange={onChangeTypeSelect}
                    />
                    <DateFilterSelect
                        defaultValue={getDateOptionByValue(findDateRangeKey(searchParams))}
                        onChange={onChangeDateSelect}
                    />
                    <StyledExportButton size={'md'} variant={'secondary'} onClick={onClickExport}>
                        {isLoadingExport ? <Spinner color={palette.light.primary['700']} size={20} /> : <GridIcon />}
                        <p>{t('tickets:export')}</p>
                    </StyledExportButton>
                </Flexbox>
            }
        >
            {isLoading ? (
                <LoadingScreen />
            ) : (
                <>
                    <ConversationSelector
                        currentConversationIndex={currentConversationIndex}
                        setCurrentConversationIndex={setCurrentConversationIndex}
                        isLoading={isLoading}
                        isError={isError}
                        isChangingPage={isFetchingNextPage}
                        onChangePage={fetchNextPage}
                        conversations={conversations}
                        totalConversation={totalConversation}
                        setTicketIdToAside={setTicketIdToAside}
                    />
                    {ticketIdToAside && (
                        <ConversationsTicketsAside
                            onClickOverlay={onCloseAside}
                            onCloseAside={onCloseAside}
                            ticketId={ticketIdToAside}
                        />
                    )}
                </>
            )}
        </StyledPageLayoutConversationHystory>
    )
}

ConversationHistory.displayName = 'Conversation'
