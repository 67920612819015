import styled, {css} from 'styled-components'

export const StyledMarkdownText = styled.div(
    ({theme: {palette, typography, spacing}}) => css`
        ${typography.textMd};
        color: ${palette.neutral[900]};

        h1 {
            ${typography.displaySm};
            font-weight: 500;
            margin: ${spacing * 2}px 0;
        }

        h2 {
            ${typography.textXl};
            font-weight: 500;
            margin: ${spacing * 2}px 0;
        }

        h3 {
            ${typography.textLg};
            font-weight: 500;
            margin: ${spacing * 2}px 0;
        }

        p {
            margin-bottom: ${spacing * 2}px 0;
        }

        & ul,
        ol {
            margin-bottom: ${spacing * 2}px;
            padding-left: ${spacing * 4}px;
        }

        & ol {
            list-style: auto;
        }

        & ul {
            list-style: circle;
        }

        & strong,
        b {
            font-weight: 500;
        }

        & em,
        i {
            font-style: italic;
        }

        & a {
            color: ${palette.primary[600]};
            text-decoration: underline;
            opacity: 0.8;
        }

        & a:hover {
            opacity: 1;
        }

        & code {
            ${typography.textSm};
            display: block;
            font-family: monospace;
            padding: ${spacing * 2}px;
        }
    `
)
