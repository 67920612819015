import styled, {css} from 'styled-components'

export const LoadingScreenWrapper = styled.div`
    ${() => css`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    `}
`

export const LoadingScreenBox = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        text-align: center;
        width: 360px;

        & h3 {
            ${typography.displaySm};
            font-weight: 600;
            color: ${palette.neutral['900']};
            margin-top: ${spacing * 6}px;
        }

        & p {
            ${typography.textMd};
            margin: ${spacing * 2}px 0 ${spacing * 14}px 0;
        }
    `}
`
