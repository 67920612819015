import styled from 'styled-components'

export const MOBILE_TABLE_SCROLLBAR_WIDTH = '4px'

type StyledMobileTableProps = {
    $isSorter: boolean
}

export const StyledMobileTable = styled.div<StyledMobileTableProps>`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: ${({$isSorter}) => ($isSorter ? 'auto 1fr' : '1fr')};
`
