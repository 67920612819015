import {boolean, z} from 'zod'
import {DateRangeParams, PaginationParams, PaginationResponse, ResponseType} from '@/types/commons.ts'
export const Conversation = z.object({
    id: z.number(),
    title: z.string().nullable(),
    sessionId: z.string(),
    isSatisfied: boolean().nullable(),
    endsAt: z.string().nullable(),
    createdAt: z.string(),
    ticketId: z.number().nullable()
})
export type Conversation = z.infer<typeof Conversation>

export const HttpGetConversationResponse = z.object({
    data: z.array(Conversation),
    meta: PaginationResponse
})
export type HttpGetConversationResponse = z.infer<typeof HttpGetConversationResponse>
export const ConversationSearchParams = z.object({
    orderBy: z.enum(['created_at']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    type: z.string().optional(),
    ...DateRangeParams.shape
})
export type ConversationSearchParams = z.infer<typeof ConversationSearchParams>

export const HttpGetConversationParams = z.object({
    responseType: ResponseType,
    ...ConversationSearchParams.shape,
    ...PaginationParams.shape
})
export type HttpGetConversationParams = z.infer<typeof HttpGetConversationParams>

export const HttpExportConversationResponse = z.object({
    data: z.string()
})
export type HttpExportConversationResponse = z.infer<typeof HttpExportConversationResponse>
