import {FC, PropsWithChildren, ReactNode} from 'react'
import {StyledPageContainer} from '@layouts/page-layout/style.ts'
import {StyledPageHeaderText} from '@components/commons/page-hero/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {
    StyledTicketPageHeaderContainer,
    StyledTicketPageHeaderWrapper,
    StyleTabSection
} from '@/features/tickets/components/tickets-page-layout/style.ts'

interface TicketsPageLayoutProps extends PropsWithChildren {
    title: string
    subtitle?: string | null
    sideComponent?: ReactNode
    tabsSection: ReactNode
}

export const TicketsPageLayout: FC<TicketsPageLayoutProps> = ({
    children,
    title,
    subtitle,
    sideComponent,
    tabsSection
}) => {
    return (
        <StyledPageContainer fullWidth>
            <StyledTicketPageHeaderWrapper direction={'column'} align={'stretch'} gap={6}>
                <StyledTicketPageHeaderContainer>
                    <StyledPageHeaderText>
                        <Flexbox align={'center'} gap={2}>
                            <h1>{title}</h1>
                        </Flexbox>
                        {subtitle && <p>{subtitle}</p>}
                    </StyledPageHeaderText>
                    {sideComponent && <div>{sideComponent}</div>}
                </StyledTicketPageHeaderContainer>
                <StyleTabSection>{tabsSection}</StyleTabSection>
            </StyledTicketPageHeaderWrapper>
            {children}
        </StyledPageContainer>
    )
}
