import React from 'react'
import {Container} from '@components/ui/container/Container.tsx'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {ChangePasswordForm} from '@/features/auth/components/change-password-form/ChangePasswordForm.tsx'
import {StyledAuthBoxWrapper, StyledAuthWrapper} from '@pages/login/style.ts'
import Logo from '@assets/images/logo_icon.svg'

export const SetPassword: React.FC = () => {
    const {t} = useTranslation()

    return (
        <Container>
            <StyledAuthWrapper>
                <StyledAuthBoxWrapper>
                    <Flexbox justify={'center'}>
                        <img src={Logo} alt={'logo'} width={48} />
                    </Flexbox>
                    <Flexbox direction={'column'} gap={3} align={'center'}>
                        <h4>{t('setPassword:title')}</h4>
                        <p>{t('setPassword:subtitle')}</p>
                    </Flexbox>
                    <ChangePasswordForm isFirstLogin />
                </StyledAuthBoxWrapper>
            </StyledAuthWrapper>
        </Container>
    )
}

SetPassword.displayName = 'ChangePassword'
