import {FC} from 'react'
import {HttpDashboardData, HttpDashboardDataParams} from '@/features/dashboard/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {DashboardCard} from '@/features/dashboard/component/card/Card.tsx'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {GraphicChart} from '@/features/dashboard/graphic-chart/GraphicChart.tsx'
import {round} from '@utilities/helpers.ts'
import {useTranslation} from 'react-i18next'
import {
    StyledCardFooter,
    StyledDashboardMetricsSectionGrid,
    StyledDashboardTicketSectionGrid
} from '@/features/dashboard/component/dashboard-data/Style.ts'
import {dashboardLabelDate} from '@/features/dashboard/utils.ts'

interface DashboardDataProps {
    data: HttpDashboardData
    searchParams: HttpDashboardDataParams
}
export const DashboardData: FC<DashboardDataProps> = ({data, searchParams}) => {
    const {t} = useTranslation()
    return (
        <Flexbox direction={'column'} gap={6}>
            <h4>{t('dashboard:last_ticket_conversation')}</h4>
            <StyledDashboardTicketSectionGrid>
                <Flexbox gap={6} direction={'column'}>
                    <DashboardCard
                        title={t('dashboard:card:title:opened_ticket')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={data.openedTickets.currentPeriod.toString()}
                        isLastPeriodZero={data.openedTickets.isLastPeriodZero}
                        isPositive={data.openedTickets.comparedToLastPeriodPercentage > 0}
                        percentage={data.openedTickets.comparedToLastPeriodPercentage}
                        footer={
                            <StyledCardFooter justify={'end'}>
                                <ButtonLink size={'sm'} to={routes.TICKETS.path} variant={'tertiary'}>
                                    {t('dashboard:view_all')}
                                </ButtonLink>
                            </StyledCardFooter>
                        }
                    />
                    <DashboardCard
                        title={t('dashboard:card:title:opened_conversation')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={data.openedConversations.currentPeriod.toString()}
                        isLastPeriodZero={data.openedConversations.isLastPeriodZero}
                        isPositive={data.openedConversations.comparedToLastPeriodPercentage > 0}
                        percentage={data.openedConversations.comparedToLastPeriodPercentage}
                        footer={
                            <StyledCardFooter justify={'end'}>
                                <ButtonLink size={'sm'} to={routes.CONVERSATION_HISTORY.path} variant={'tertiary'}>
                                    {t('dashboard:view_all')}
                                </ButtonLink>
                            </StyledCardFooter>
                        }
                    />
                </Flexbox>
                <GraphicChart searchParams={searchParams} data={data.chartStats} />
            </StyledDashboardTicketSectionGrid>
            <div>
                <h4>{t('dashboard:metrics')}</h4>
            </div>
            <StyledDashboardMetricsSectionGrid>
                <Flexbox gap={6}>
                    <DashboardCard
                        title={t('dashboard:card:title:average_conversation_duration')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={`${round(data.averageConversationDuration.currentPeriod)}s`}
                        isLastPeriodZero={data.averageConversationDuration.isLastPeriodZero}
                        isPositive={data.averageConversationDuration.comparedToLastPeriodPercentage > 0}
                        percentage={data.averageConversationDuration.comparedToLastPeriodPercentage}
                    />
                    <DashboardCard
                        title={t('dashboard:card:title:average_conversation_messages')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={round(data.averageConversationMessages.currentPeriod).toString()}
                        isLastPeriodZero={data.averageConversationMessages.isLastPeriodZero}
                        isPositive={data.averageConversationMessages.comparedToLastPeriodPercentage > 0}
                        percentage={data.averageConversationMessages.comparedToLastPeriodPercentage}
                    />
                    <DashboardCard
                        title={t('dashboard:card:title:fallback_percentage')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={`${round(data.fallbackPercentage.currentPeriod)}%`}
                        isLastPeriodZero={data.fallbackPercentage.isLastPeriodZero}
                        isPositive={data.fallbackPercentage.comparedToLastPeriodPercentage > 0}
                        percentage={data.fallbackPercentage.comparedToLastPeriodPercentage}
                    />
                </Flexbox>
                <Flexbox gap={6}>
                    <DashboardCard
                        title={t('dashboard:card:title:conversations_satisfaction')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={`${round(data.conversationsSatisfaction.currentPeriod)}%`}
                        isLastPeriodZero={data.conversationsSatisfaction.isLastPeriodZero}
                        isPositive={data.conversationsSatisfaction.comparedToLastPeriodPercentage > 0}
                        percentage={data.conversationsSatisfaction.comparedToLastPeriodPercentage}
                    />
                    <DashboardCard
                        title={t('dashboard:card:title:messages_satisfaction')}
                        label={t('dashboard:card:label:as_compared_to', {
                            date: dashboardLabelDate(searchParams.startDate)
                        })}
                        primaryData={`${round(data.messagesSatisfaction.currentPeriod)}%`}
                        isLastPeriodZero={data.messagesSatisfaction.isLastPeriodZero}
                        isPositive={data.messagesSatisfaction.comparedToLastPeriodPercentage > 0}
                        percentage={data.messagesSatisfaction.comparedToLastPeriodPercentage}
                    />
                </Flexbox>
            </StyledDashboardMetricsSectionGrid>
        </Flexbox>
    )
}
