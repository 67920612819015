import {ReactNode} from 'react'
import {StyledNavLink} from '@components/ui/navlink/style.ts'

interface NavLinkProps {
    size?: 'sm' | 'md' | 'lg'
    variant?: 'ghost' | 'primary'
    name?: string
    path: string
    end?: boolean
    icon?: ReactNode
}

export const NavLink = ({icon, size = 'sm', name, path, end = true, variant = 'ghost'}: NavLinkProps) => (
    <StyledNavLink size={size} variant={variant} to={path} end={end}>
        {icon && icon}
        {name && name}
    </StyledNavLink>
)

NavLink.displayName = 'NavLink'
