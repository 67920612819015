import {useInfiniteQuery} from '@tanstack/react-query'
import {infiniteQueryFetchNextPage, QUERY_KEYS} from '@/queryClient'
import {parseAxiosPromise} from '@/axiosInstance.ts'
import {captureException} from '@sentry/react'
import {httpGetTickets} from '@/features/tickets/services/tickets.http.ts'
import {HttpGetTicketsParams, HttpGetTicketsResponse} from '@/features/tickets/types.ts'

export const useGetTickets = (params: Omit<HttpGetTicketsParams, 'page'>) => {
    const query = useInfiniteQuery({
        queryKey: [
            QUERY_KEYS.TICKETS,
            params.searchString,
            params.orderBy,
            params.orderDirection,
            params.endDate,
            params.startDate,
            params.isCompleted
        ],
        queryFn: ({pageParam = 1}) =>
            parseAxiosPromise({
                axiosPromise: httpGetTickets({
                    ...params,
                    page: pageParam
                }),
                onZodError: captureException,
                responseShape: HttpGetTicketsResponse
            }),
        initialPageParam: 1,
        getNextPageParam: ({meta}) => (meta.current_page < meta.last_page ? meta.current_page + 1 : null)
    })

    const remappedData = query.data?.pages[0]?.data?.length ? query.data.pages.flatMap(page => page.data) : []
    return {
        ...query,
        fetchNextPage: () => infiniteQueryFetchNextPage(query),
        remappedData
    }
}
