import {FC} from 'react'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {
    StyledConfirmModalBody,
    StyledConfirmModalCloseButton,
    StyledConfirmModalContent,
    StyledConfirmModalFooter,
    StyledConfirmModalHeader
} from '@components/commons/confirm-modal/style.ts'
import {Button} from '@components/ui/button/Button.tsx'
import {useTranslation} from 'react-i18next'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'

interface ConfirmModalProps {
    modalTitle: string
    title: string
    description?: string
    onClose: () => void
    primaryCtaLabel: string
    onClickCta: () => void
    disablePrimaryCta: boolean
    isPending: boolean
}
export const ConfirmModal: FC<ConfirmModalProps> = ({
    modalTitle,
    title,
    description,
    onClose,
    onClickCta,
    primaryCtaLabel,
    disablePrimaryCta,
    isPending
}) => {
    const {t} = useTranslation()
    return (
        <Modal onOverlayClick={onClose} width={444}>
            <StyledConfirmModalContent>
                <StyledConfirmModalHeader justify={'center'}>
                    <StyledConfirmModalCloseButton $size={'sm'} $variant={'ghost'} onClick={onClose}>
                        <XCloseIcon />
                    </StyledConfirmModalCloseButton>
                    <p>{modalTitle}</p>
                </StyledConfirmModalHeader>
                <StyledConfirmModalBody direction={'column'} gap={2}>
                    <h4>{title}</h4>
                    {description && <p>{description}</p>}
                </StyledConfirmModalBody>
                <StyledConfirmModalFooter justify={'space-between'}>
                    <Button variant={'ghost'} onClick={onClose}>
                        {t('commons:cancel')}
                    </Button>
                    <Button variant={'primary'} onClick={onClickCta} disabled={disablePrimaryCta}>
                        {primaryCtaLabel}
                        {isPending && <Spinner size={24} />}
                    </Button>
                </StyledConfirmModalFooter>
            </StyledConfirmModalContent>
        </Modal>
    )
}
