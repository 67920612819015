import {z} from 'zod'
import {DateRangeParams, PaginationParams, PaginationResponse, ResponseType} from '@/types/commons.ts'

export const Attachment = z.object({
    id: z.number(),
    ticketId: z.number(),
    name: z.string(),
    extension: z.string(),
    pathLocation: z.string(),
    createdAt: z.string(),
    url: z.string(),
    type: z.enum(['delegation', 'identity_document']).nullable()
})
export type Attachment = z.infer<typeof Attachment>
export const Ticket = z.object({
    id: z.number(),
    conversationId: z.number(),
    senderFirstName: z.string(),
    senderLastName: z.string(),
    phoneNumber: z.string(),
    email: z.string().email(),
    patientFirstName: z.string().nullable(),
    patientLastName: z.string().nullable(),
    content: z.string(),
    type: z.enum(['praise', 'complaint', 'help', 'refund', 'suggestion', 'info']),
    createdAt: z.string(),
    attachments: z.array(Attachment),
    completedBy: z.number().nullable(),
    completedAt: z.string().nullable()
})
export type Ticket = z.infer<typeof Ticket>

export const HttpGetTicketsResponse = z.object({
    data: z.array(Ticket),
    meta: PaginationResponse
})
export type HttpGetTicketsResponse = z.infer<typeof HttpGetTicketsResponse>
export const TicketsSearchParams = z.object({
    searchString: z.string().optional(),
    ...DateRangeParams.shape,
    orderBy: z.enum(['id', 'type', 'created_at', 'sender_last_name', 'patient_last_name']).optional(),
    orderDirection: z.enum(['asc', 'desc']).optional(),
    isCompleted: z.string().optional()
})
export type TicketsSearchParams = z.infer<typeof TicketsSearchParams>

export const HttpGetTicketsParams = z.object({
    ...PaginationParams.shape,
    ...TicketsSearchParams.shape,
    responseType: ResponseType
})
export type HttpGetTicketsParams = z.infer<typeof HttpGetTicketsParams>

export const HttpGetTicketDetailsParams = z.object({
    ticketId: z.number(),
    responseType: ResponseType
})
export type HttpGetTicketDetailsParams = z.infer<typeof HttpGetTicketDetailsParams>

export const HttpUpdateTicketBody = z.object({
    completedBy: z.number().nullable(),
    completedAt: z.string().nullish()
})
export type HttpUpdateTicketBody = z.infer<typeof HttpUpdateTicketBody>
export const HttpUpdateTicketParams = z.object({
    data: HttpUpdateTicketBody,
    ticketId: z.number()
})
export type HttpUpdateTicketParams = z.infer<typeof HttpUpdateTicketParams>
