import {AvatarFallback, AvatarImage} from '@radix-ui/react-avatar'
import {AvatarRoot} from '@components/ui/avatar/style.ts'
import {getInitials} from '@utilities/helpers.ts'

interface AvatarProps {
    name: string
    imageUrl: string
    variant?: 'primary' | 'user' | 'assistant'
    size?: 'sm' | 'md'
}

export const Avatar = ({name, imageUrl, variant, size}: AvatarProps) => (
    <AvatarRoot $variant={variant} $size={size}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback delayMs={600}>{getInitials(name)}</AvatarFallback>
    </AvatarRoot>
)

Avatar.displayName = 'Avatar'
