import React, {FC, useMemo} from 'react'
import {Table} from '@components/commons/table/Table.tsx'
import useWindowDimensions from '@hooks/useWindowDimensions.ts'
import {useTranslation} from 'react-i18next'
import {TableDataCell} from '@components/commons/table/TableDataCell/TableDataCell.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Chip} from '@components/ui/chip/Chip.tsx'
import {User} from '@/features/users/types.ts'
import {Edit01Icon, Key02Icon, SearchLgIcon, Trash01Icon} from '@components/ui/icon/Icon.tsx'
import {Button} from '@components/ui/button/Button.tsx'
import {capitalize, formatLocaleDate} from '@utilities/helpers.ts'
import {StyledTrashButton} from '@/features/users/components/users-table/style.ts'
import {Tooltip} from '@components/ui/tooltip/Tooltip.tsx'
import {EmptySearch} from '@components/commons/empty-search/EmptySearch.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'

interface UsersTableProps {
    users: User[]
    isChangingPage: boolean
    isError: boolean
    isLoading: boolean
    onChangePage?: () => void
    setUserToDelete: React.Dispatch<User>
    setUserToUpdate: React.Dispatch<User>
    setEmailToShowForgotModal: React.Dispatch<string>
    onResetSearchCb?: () => void
    searchValue?: string
}
export const UsersTable: FC<UsersTableProps> = ({
    users,
    isChangingPage,
    isError,
    isLoading,
    onChangePage,
    setUserToDelete,
    setUserToUpdate,
    setEmailToShowForgotModal,
    onResetSearchCb,
    searchValue
}) => {
    const {width} = useWindowDimensions()
    const isMobile = useMemo(() => width < 960, [width])
    const {t} = useTranslation()
    const authUser = useAuthStore(state => state.user)

    return (
        <Table
            data={users}
            isMobile={isMobile}
            isLoading={isLoading}
            onChangePage={onChangePage}
            isChangingPage={isChangingPage}
            isError={isError}
            searchValue={searchValue}
            columns={[
                {
                    label: t('users:table:labels:name'),
                    width: '25%',
                    cellRender: user => (
                        <TableDataCell>
                            <h4
                                title={`${capitalize(user.firstName)} ${capitalize(user.lastName)}`}
                            >{`${capitalize(user.firstName)} ${capitalize(user.lastName)}`}</h4>
                            <span title={user.email}>{user.email}</span>
                        </TableDataCell>
                    )
                },
                {
                    label: t('users:table:labels:permissions'),
                    width: '15%',
                    overflow: 'visible',
                    cellRender: user => (
                        <TableDataCell>
                            <Flexbox gap={1}>
                                {user.permissions.map((permit, key) => (
                                    <Chip
                                        key={key}
                                        label={t(`users:table:${permit.name}`)}
                                        id={permit.id.toString()}
                                        variant={'neutral'}
                                    />
                                ))}
                            </Flexbox>
                        </TableDataCell>
                    )
                },
                {
                    label: '',
                    width: '15%',
                    cellRender: () => ''
                },
                {
                    label: t('users:table:labels:created_At'),
                    width: '15%',
                    cellRender: user => (
                        <TableDataCell>
                            <p>{user.createdAt ? formatLocaleDate(user.createdAt, 'DD/MMM/YYYY') : '--'}</p>
                        </TableDataCell>
                    )
                },
                {
                    label: t('users:table:labels:state'),
                    width: '15%',
                    cellRender: user => (
                        <TableDataCell>
                            {user.isActive ? (
                                <Chip
                                    id={user.id.toString()}
                                    radiusFull
                                    label={t('users:table:active')}
                                    variant={'success'}
                                />
                            ) : (
                                <Chip
                                    id={user.id.toString()}
                                    radiusFull
                                    label={t('users:table:invited')}
                                    variant={'primary'}
                                />
                            )}
                        </TableDataCell>
                    )
                },
                {
                    label: '',
                    width: '15%',
                    alignment: 'right',
                    overflow: 'visible',
                    cellRender: user => (
                        <TableDataCell>
                            <Flexbox>
                                <Tooltip
                                    trigger={
                                        <Button variant={'ghost'} onClick={() => setEmailToShowForgotModal(user.email)}>
                                            <Key02Icon />
                                        </Button>
                                    }
                                    content={t('users:table:tooltip:recovery_password')}
                                />
                                <Tooltip
                                    trigger={
                                        <Button
                                            shape={'square'}
                                            variant={'ghost'}
                                            onClick={() => setUserToUpdate(user)}
                                        >
                                            <Edit01Icon />
                                        </Button>
                                    }
                                    content={t('users:table:tooltip:update')}
                                />
                                <Tooltip
                                    trigger={
                                        <StyledTrashButton
                                            variant={'ghost'}
                                            onClick={() => setUserToDelete(user)}
                                            disabled={user.id === authUser?.id}
                                        >
                                            <Trash01Icon />
                                        </StyledTrashButton>
                                    }
                                    content={t('users:table:tooltip:delete')}
                                />
                            </Flexbox>
                        </TableDataCell>
                    )
                }
            ]}
            emptySearchStateComponent={
                <EmptySearch
                    title={t('commons:no_results')}
                    icon={<SearchLgIcon />}
                    onClearSearch={() => onResetSearchCb && onResetSearchCb()}
                />
            }
        />
    )
}
