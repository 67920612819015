import axios from '../../../axiosInstance.ts'
import {HttpCreateUserBody, HttpGetUsersParams, HttpUpdateUserBody} from '@/features/users/types.ts'
import {PaginationParams} from '@/types/commons.ts'

/**
 * httpGetUsers
 * Here we will fetch all the users data for the dash table
 */

export const httpGetUsers = async (params: HttpGetUsersParams) => {
    return await axios.get(`/users`, {params})
}

/**
 * httpCreateUser
 * Here we will create the user
 */

export const httpCreateUser = async (params: HttpCreateUserBody) => {
    return await axios.post(`/users`, {...params})
}

/**
 * httpUpdateUser
 * Here we will update the user
 */

export const httpUpdateUsers = async (userUpdated: HttpUpdateUserBody) => {
    return await axios.patch(`/users/${userUpdated.userId}`, userUpdated.data)
}

/**
 * httpDeleteUsers
 * Here we will delete the user
 */

export const httpDeleteUsers = async (userId: number) => {
    return await axios.delete(`/users/${userId}`)
}

/**
 * httpGetPermissions
 * Here we will fetch all the permission data for the select
 */

export const httpGetPermissions = async (params: PaginationParams) => {
    return await axios.get(`/permissions`, {params})
}
