import {FC, ReactNode} from 'react'
import {
    StyledCardContainer,
    StyledCardWrapper,
    StyledPercentage,
    StyledPercentageContainer
} from '@/features/dashboard/component/card/style.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {round} from '@utilities/helpers.ts'
import {TrendDown01Icon, TrendUp01Icon} from '@components/ui/icon/Icon.tsx'

interface DashboardCardProps {
    title: string
    percentage?: number
    label: string
    primaryData: string
    footer?: ReactNode
    tooltip?: ReactNode
    isLastPeriodZero: boolean
    isPositive: boolean
}
export const DashboardCard: FC<DashboardCardProps> = ({
    footer,
    isLastPeriodZero,
    isPositive,
    primaryData,
    title,
    percentage,
    label,
    tooltip
}) => {
    return (
        <StyledCardWrapper>
            <StyledCardContainer>
                <Flexbox direction={'column'} gap={2}>
                    <Flexbox justify={'space-between'}>
                        <p>{title}</p>
                        {tooltip && tooltip}
                    </Flexbox>
                    <StyledPercentageContainer justify={'space-between'} align={'center'}>
                        <h4>{primaryData}</h4>
                        {percentage && !isLastPeriodZero ? (
                            <Flexbox gap={2}>
                                <StyledPercentage gap={2} $isPositive={isPositive}>
                                    {isPositive ? <TrendUp01Icon size={20} /> : <TrendDown01Icon size={20} />}

                                    <p>
                                        {isPositive && '+'}
                                        {round(percentage)}%
                                    </p>
                                </StyledPercentage>
                                <p>{label}</p>
                            </Flexbox>
                        ) : (
                            <Flexbox gap={1}>
                                <StyledPercentage $isPositive={isPositive}>
                                    <p>+{primaryData}</p>
                                </StyledPercentage>
                                <p>{label}</p>
                            </Flexbox>
                        )}
                    </StyledPercentageContainer>
                </Flexbox>
            </StyledCardContainer>
            {footer}
        </StyledCardWrapper>
    )
}
