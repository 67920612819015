import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {spacing} from '@/theme/theme.ts'
import {StyledButton} from '@components/ui/button/style.ts'

export const StyledConfirmModalContent = styled.div`
    ${({theme: {typography, palette}}) => css`
        & h4 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & p {
            ${typography.textMd};
            color: ${palette.neutral['900']};
        }
    `}
`
export const StyledConfirmModalHeader = styled(Flexbox)`
    ${({theme: {palette}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `}
`
export const StyledConfirmModalCloseButton = styled(StyledButton)`
    ${() => css`
        position: absolute;
        left: 8px;
        top: 10px;
    `}
`
export const StyledConfirmModalBody = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        padding: ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `}
`
export const StyledConfirmModalFooter = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `}
`
