import {z} from 'zod'
import {PaginationParams, PaginationResponse, ResponseType} from '@/types/commons.ts'

export const ConversationMessage = z.object({
    id: z.number(),
    conversationId: z.number(),
    previousMessageId: z.number().nullable(),
    isSatisfied: z.boolean().nullable(),
    systemRole: z.enum(['system', 'user', 'assistant']),
    message: z.string(),
    createdAt: z.string()
})
export type ConversationMessage = z.infer<typeof ConversationMessage>

export const HttpGetMessagesParams = z.object({
    ...PaginationParams.shape,
    responseType: ResponseType,
    conversationId: z.number()
})
export type HttpGetMessagesParams = z.infer<typeof HttpGetMessagesParams>

export const HttpGetMessagesResponse = z.object({
    data: z.array(ConversationMessage),
    meta: PaginationResponse
})
export type HttpGetMessagesResponse = z.infer<typeof HttpGetMessagesResponse>
