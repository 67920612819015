import {MUTATION_KEYS, QUERY_KEYS, queryClient} from '@/queryClient.ts'
import {useMutation} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {ErrorResponseData} from '@/types/commons.ts'
import {httpDeleteUsers} from '@/features/users/services/users.http.ts'

type useCreateUserProps = {
    onSuccess?: () => void
    onError?: (error: AxiosError<ErrorResponseData>) => void
}

export const useDeleteUser = (options?: useCreateUserProps) => {
    return useMutation({
        mutationKey: [MUTATION_KEYS.DELETE_USER],
        mutationFn: (userId: number) => httpDeleteUsers(userId),
        onError: (error: AxiosError<ErrorResponseData>) => options?.onError?.(error),
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: [QUERY_KEYS.USERS]})
            options?.onSuccess?.()
        }
    })
}
