import styled, {css} from 'styled-components'
import {Link} from 'react-router-dom'
import {
    makeButtonBaseStyle,
    makeButtonSizeStyle,
    makeButtonVariantStyle,
    StyledButtonProps
} from '@components/ui/button/style.ts'

export const StyledButtonLink = styled(Link)<StyledButtonProps>(
    ({theme, ...props}) => css`
        ${makeButtonSizeStyle(theme)[props.$size ?? 'md'][props.$shape ?? 'rectangle']};
        ${makeButtonVariantStyle(theme, false)[props.$variant ?? 'primary']};
        ${makeButtonBaseStyle(theme, {
            $fullWidth: props.$fullWidth,
            $shape: props.$shape,
            $size: props.$size,
            disabled: false
        })};
    `
)

export const StyledButtonLinkDisabled = styled.a<StyledButtonProps>(
    ({theme, ...props}) => css`
        ${makeButtonSizeStyle(theme)[props.$size ?? 'md'][props.$shape ?? 'rectangle']};
        ${makeButtonVariantStyle(theme, true)[props.$variant ?? 'primary']};
        ${makeButtonBaseStyle(theme, {
            $fullWidth: props.$fullWidth,
            $shape: props.$shape,
            $size: props.$size,
            disabled: true
        })};
    `
)
