import {FC} from 'react'
import {Select, SelectValue} from '@components/commons/select/Select.tsx'
import {SingleValue} from 'react-select'
import {User01Icon} from '@components/ui/icon/Icon.tsx'
import {StyledUserTypeFilterWrapper} from '@components/commons/user-type-filter-select/style.ts'
import {selectUserTypeOption} from '@components/commons/user-type-filter-select/utils.ts'

interface DateFilterSelectProps {
    onChange: (newValue: SingleValue<SelectValue>) => void
    defaultValue?: SelectValue | []
}
export const UserTypeFilterSelect: FC<DateFilterSelectProps> = ({onChange, defaultValue}) => {
    return (
        <StyledUserTypeFilterWrapper>
            <Select
                icon={<User01Icon />}
                onChange={newValue => onChange(newValue as SingleValue<SelectValue>)}
                defaultValue={defaultValue || selectUserTypeOption[0]}
                options={selectUserTypeOption}
            />
        </StyledUserTypeFilterWrapper>
    )
}
