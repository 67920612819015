import React, {useRef, useState} from 'react'
import {useTicketsTable} from '@/features/tickets/services/query/useTicketsTable.ts'
import {TicketsTable} from '@/features/tickets/components/tickets-table/TicketsTable.tsx'
import {useTranslation} from 'react-i18next'
import {GridIcon, SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {TicketsAside} from '@/features/tickets/components/tickets-aside/TicketsAside.tsx'
import {StyledExportButton} from '@pages/tickets/style.ts'
import {EmptyTickets} from '@/features/tickets/components/empty-tickets/EmptyTickets.tsx'
import {useAsync} from '@hooks/useAsync.ts'
import {httpExportTickets} from '@/features/tickets/services/tickets.http.ts'
import {Spinner} from '@components/ui/spinner/Spinner.tsx'
import {palette} from '@/theme/palette.ts'
import {generateCsvDownload} from '@utilities/helpers.ts'
import {findDateRangeKey, getDateOptionByValue, selectDateValue} from '@components/commons/date-filter-select/utils.ts'
import {DateFilterSelect} from '@components/commons/date-filter-select/DateFilterSelect.tsx'
import {SingleValue} from 'react-select'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {TicketsPageLayout} from '@/features/tickets/components/tickets-page-layout/TicketsPageLayout.tsx'
import {TabButton} from '@components/ui/tab-button/TabButton.tsx'

export const Tickets: React.FC = () => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const {
        remappedData: tickets,
        onSearch,
        searchValue,
        fetchNextPage,
        isLoading,
        isError,
        isFetchingNextPage,
        onResetSearch,
        searchParams,
        setSearchParams,
        toggleCompletedHandler,
        sorter: ticketSorter
    } = useTicketsTable()
    const [currentTicketIndex, setCurrentTicketIndex] = useState<number | null>(null)
    const {run: runExport, isLoading: isLoadingExport} = useAsync()
    const [currentSection, setCurrentSection] = useState<'open' | 'closed'>(
        searchParams.isCompleted ? (searchParams.isCompleted === 'false' ? 'open' : 'closed') : 'open'
    )

    const onClickExport = async () => {
        const exportData = await runExport(
            httpExportTickets({
                page: 1,
                limit: tickets.length,
                startDate: searchParams.startDate,
                endDate: searchParams.endDate,
                responseType: 'regular'
            })
        )
        generateCsvDownload({data: exportData.data, fileName: 'Ticket'})
    }
    const onChangeDateSelect = (newValue: SingleValue<SelectValue>) => {
        onCloseAside()
        if (newValue) {
            setSearchParams(selectDateValue[newValue.value])
        }
    }
    const onCloseAside = () => {
        setCurrentTicketIndex(null)
    }
    const onClickRow = (itemIndex: number) => {
        if (currentTicketIndex !== null) {
            setCurrentTicketIndex(itemIndex)
        }
    }
    const onClickOpenTab = () => {
        onCloseAside()
        setCurrentSection('open')
        toggleCompletedHandler(false)
    }
    const onClickClosedTab = () => {
        onCloseAside()
        setCurrentSection('closed')
        toggleCompletedHandler(true)
    }

    return (
        <TicketsPageLayout
            title={t('tickets:title')}
            sideComponent={
                <Flexbox align={'center'} gap={3}>
                    <DateFilterSelect
                        defaultValue={getDateOptionByValue(findDateRangeKey(searchParams))}
                        onChange={onChangeDateSelect}
                    />
                    <StyledExportButton
                        size={'md'}
                        variant={'secondary'}
                        onClick={onClickExport}
                        disabled={isLoadingExport}
                    >
                        {isLoadingExport ? <Spinner color={palette.light.primary['700']} size={20} /> : <GridIcon />}

                        <p>{t('tickets:export')}</p>
                    </StyledExportButton>
                    <InputText
                        onChange={e => onSearch(e.currentTarget.value)}
                        defaultValue={searchValue}
                        ref={searchRef}
                        type={'text'}
                        placeholder={t('commons:search_placeholder')}
                        typeIcon={<SearchLgIcon size={18} />}
                        inputSize={'sm'}
                    />
                </Flexbox>
            }
            tabsSection={
                <Flexbox>
                    <TabButton onClick={onClickOpenTab} $isActive={currentSection === 'open'}>
                        {t('tickets:open')}
                    </TabButton>
                    <TabButton onClick={onClickClosedTab} $isActive={currentSection === 'closed'}>
                        {t('tickets:closed')}
                    </TabButton>
                </Flexbox>
            }
        >
            {tickets.length === 0 && !isLoading && !isError && !searchValue ? (
                <EmptyTickets
                    title={t('tickets:emptyTickets:title')}
                    description={t('tickets:emptyTickets:subtitle', {
                        data: currentSection === 'open' ? 'aperto' : 'chiuso'
                    })}
                />
            ) : (
                <>
                    <TicketsTable
                        tickets={tickets}
                        isChangingPage={isFetchingNextPage}
                        isError={isError}
                        isLoading={isLoading}
                        onChangePage={fetchNextPage}
                        onResetSearchCb={() => onResetSearch(searchRef)}
                        searchValue={searchValue}
                        setCurrentTicketIndex={setCurrentTicketIndex}
                        currentTicketIndex={currentTicketIndex}
                        sorter={{
                            ...ticketSorter,
                            onSort: params => {
                                onCloseAside()
                                ticketSorter.onSort(params)
                            }
                        }}
                        onClickRow={onClickRow}
                        currentSection={currentSection}
                    />
                    {currentTicketIndex !== null && (
                        <TicketsAside
                            currentSection={currentSection}
                            setCurrentTicketIndex={setCurrentTicketIndex}
                            onCloseAside={onCloseAside}
                            currentTicketIndex={currentTicketIndex}
                        />
                    )}
                </>
            )}
        </TicketsPageLayout>
    )
}

Tickets.displayName = 'Tickets'
