import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {SearchLgIcon} from '@components/ui/icon/Icon.tsx'
import {StyledContainer, StyledNotFoundWrapper} from '@pages/not-found/style.ts'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {roleBasedRedirect} from '@/features/auth/helpers.ts'
import {useAuthStore} from '@/features/auth/store/store.ts'

export const NotFound = () => {
    const {t} = useTranslation()
    const {accessToken, user} = useAuthStore()

    return (
        <StyledContainer fullHeight>
            <StyledNotFoundWrapper>
                <IconContainer>
                    <SearchLgIcon />
                </IconContainer>
                <h1>{t('notFound:title')}</h1>
                <ButtonLink variant={'primary'} size={'lg'} to={roleBasedRedirect(accessToken, user?.permissions[0])}>
                    {t('notFound:cta')}
                </ButtonLink>
            </StyledNotFoundWrapper>
        </StyledContainer>
    )
}

NotFound.displayName = 'NotFound'
