import {Navigate, Outlet, useNavigate} from 'react-router-dom'
import {containsAtLeastOne} from '@utilities/helpers.ts'
import {useEffect, useState} from 'react'
import {roleBasedRedirect} from '@/features/auth/helpers.ts'
import {routes} from '@utilities/constants/routes.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {getPermitName} from '@/features/users/utils.ts'
import {LoadingScreen} from '@components/commons/loading-screen/LoadingScreen.tsx'

export const UserRouteGuard = () => {
    const {accessToken, user} = useAuthStore()
    const navigate = useNavigate()
    const [guardCondition, setGuardCondition] = useState(false)

    useEffect(() => {
        if (user) {
            if (containsAtLeastOne(['user'], getPermitName(user.permissions))) {
                setGuardCondition(containsAtLeastOne(['user'], getPermitName(user.permissions)))
            } else {
                navigate(roleBasedRedirect(accessToken, user?.permissions[0]))
            }
        }
    }, [user])

    return (
        <>
            {!accessToken && <Navigate to={routes.LOGIN.path} />}
            {user && guardCondition ? <Outlet /> : <LoadingScreen />}
        </>
    )
}
