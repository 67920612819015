import {
    HeaderWrapper,
    HeaderGrid,
    MenuItems,
    DropdownUserInfosItems,
    StyledAiAssistanceButton
} from '@components/commons/header/style.ts'
import Logo from '@assets/images/Logo.svg'
import {Container} from '@components/ui/container/Container.tsx'
import {NavLink} from '@components/ui/navlink/NavLink.tsx'
import {useAuthStore} from '@/features/auth/store/store.ts'
import {ArrowUpRightIcon, LogoutIcon} from '@components/ui/icon/Icon.tsx'
import {logout} from '@/features/auth/store/actions.ts'
import {useTranslation} from 'react-i18next'
import {DropdownMenu} from '@components/ui/dropdown-menu/DropdownMenu.tsx'
import {Avatar} from '@components/ui/avatar/Avatar.tsx'
import {capitalize} from '@utilities/helpers.ts'
import {envVars} from '@/envVars.ts'

export type MenuItem = {
    name: string
    path: string
}

interface HeaderProps {
    items: MenuItem[]
}

export const Header = ({items}: HeaderProps) => {
    const user = useAuthStore(state => state.user)
    const {t} = useTranslation()
    const onClickAiChat = () => {
        window.location.href = import.meta.env.VITE_API_ENDPOINT.includes('k-stage')
            ? envVars.VITE_AI_STAGING_ENDPOINT
            : envVars.VITE_AI_ENDPOINT
    }
    return (
        <HeaderWrapper>
            <Container fullWidth>
                <HeaderGrid $user={user}>
                    <img src={Logo} alt={'logo'} height={28} />
                    <MenuItems>
                        {items.map(({name, path}) => (
                            <NavLink size={'sm'} variant={'primary'} path={path} name={t(name)} key={name} />
                        ))}
                    </MenuItems>
                    <StyledAiAssistanceButton size={'sm'} onClick={onClickAiChat} variant={'tertiary'}>
                        {t('commons:virtual_assistance')}
                        <ArrowUpRightIcon />
                    </StyledAiAssistanceButton>
                    {user && (
                        <DropdownMenu
                            sideOffset={8}
                            trigger={
                                <Avatar
                                    name={`${capitalize(user.firstName)} ${capitalize(user.lastName)}`}
                                    imageUrl={user.firstName}
                                />
                            }
                            actions={[
                                {
                                    component: (
                                        <DropdownUserInfosItems>
                                            <span>{`${user.firstName} ${user.lastName}`}</span>
                                            <small>{user.email}</small>
                                        </DropdownUserInfosItems>
                                    ),
                                    withSeparator: true
                                },
                                {
                                    component: t('commons:logout'),
                                    icon: <LogoutIcon size={16} />,
                                    onClickCb: logout
                                }
                            ]}
                        />
                    )}
                </HeaderGrid>
            </Container>
        </HeaderWrapper>
    )
}
