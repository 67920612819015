import {StyledMessageContent, StyledMessageWrapper} from '@components/ui/message/style.ts'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const MessageSkeleton = () => {
    return (
        <Flexbox direction={'column'} gap={4}>
            <StyledMessageWrapper gap={3}>
                <div>
                    <Skeleton height={32} width={32} />
                </div>
                <StyledMessageContent direction={'column'} gap={2}>
                    <Skeleton height={24} width={200} />
                    <Skeleton height={24} />
                </StyledMessageContent>
                <div>
                    <Skeleton height={18} width={25} />
                </div>
            </StyledMessageWrapper>

            <StyledMessageWrapper gap={3}>
                <div>
                    <Skeleton height={32} width={32} />
                </div>
                <StyledMessageContent direction={'column'} gap={2}>
                    <Skeleton height={24} width={200} />
                    <Skeleton height={24} />
                </StyledMessageContent>
                <div>
                    <Skeleton height={18} width={25} />
                </div>
            </StyledMessageWrapper>
        </Flexbox>
    )
}
