import styled, {css} from 'styled-components'

export const StyledAuthWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

export const StyledAuthBoxWrapper = styled.div`
    ${({theme: {typography, palette, spacing}}) => css`
        display: grid;
        place-items: stretch;
        gap: ${spacing * 8}px;
        width: 360px;
        & h4 {
            ${typography.displaySm};
            font-weight: 700;
            color: ${palette.neutral['900']};
            text-align: center;
        }
        & p {
            ${typography.textMd};
            text-align: center;
        }
    `}
`
