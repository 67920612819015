import axios from '../../../axiosInstance.ts'
import {LoginValidationSchema} from '@/features/auth/components/login-form/LoginFormModel.ts'
import {ForgotPasswordValidationSchema} from '@/features/auth/components/forgot-password-form/ForgotPasswordFormModel.ts'
import {HttpChangePasswordBody} from '@/features/auth/types.ts'

/**
 * This function is used to validate the code received during the sso process
 */
export const httpSSOLogin = (code: string) => {
    return axios.post(`/host-services/login`, {code})
}

/**
 * Simple Login function
 */
export const httpLogin = (data: LoginValidationSchema) => {
    return axios.post('/auth/login', data)
}

/**
 * Simple Logout function
 */
export const httpLogout = () => {
    return axios.post('/auth/logout')
}

/**
 * Simple Me function
 */
export const httpUserMe = () => {
    return axios.get('/auth/me')
}

/**
 * Simple Forgot password function
 */
export const httpForgotPassword = (data: ForgotPasswordValidationSchema) => {
    return axios.post('/password-reset', data)
}

/**
 * Simple Change password function
 */
export const httpChangePassword = (data: HttpChangePasswordBody) => {
    return axios.post('/password-reset/reset', data)
}
