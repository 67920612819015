import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledUpdateUserModalFooter = styled(Flexbox)`
    ${({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `}
`

export const StyledChangeStepContainer = styled.div`
    ${({theme: {spacing, palette}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 ${spacing * 6}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `}
`
