import {FC} from 'react'
import {Select, SelectValue} from '@components/commons/select/Select.tsx'
import {SingleValue} from 'react-select'
import {selectDateOption} from '@components/commons/date-filter-select/utils.ts'
import {StyledDateFilterWrapper} from '@components/commons/date-filter-select/style.ts'
import {CalendarIcon} from '@components/ui/icon/Icon.tsx'

interface DateFilterSelectProps {
    onChange: (newValue: SingleValue<SelectValue>) => void
    defaultValue?: SelectValue | []
}
export const DateFilterSelect: FC<DateFilterSelectProps> = ({onChange, defaultValue}) => {
    return (
        <StyledDateFilterWrapper>
            <Select
                icon={<CalendarIcon />}
                onChange={newValue => onChange(newValue as SingleValue<SelectValue>)}
                defaultValue={defaultValue || selectDateOption[2]}
                options={selectDateOption}
            />
        </StyledDateFilterWrapper>
    )
}
