import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledChangePasswordIconContainer = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        border-radius: 50px;
        background-color: ${palette.success['100']};
        padding: ${spacing * 2}px;
        color: ${palette.success['600']};
    `}
`
export const StyledCorrespondWrapper = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        & p {
            font-weight: 600;
            color: ${palette.neutral['700']};
            ${typography.textSm}
        }
    `}
`
