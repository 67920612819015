import {useFormContext} from 'react-hook-form'
import {InputText} from '@components/commons/input-text/InputText.tsx'
import {CREATE_USER_FORM_MODEL, CreateUserValidationSchema} from '../CreateUserFormModel'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {StyledCreateUserFormWrapper} from '@/features/users/components/create-user-form/style.ts'

interface DataUserForm {
    isUpdate?: boolean
}
export const DataUserForm: React.FC<DataUserForm> = ({isUpdate = false}) => {
    const {t} = useTranslation()
    const {
        register,
        formState: {errors}
    } = useFormContext<CreateUserValidationSchema>()

    return (
        <StyledCreateUserFormWrapper>
            {!isUpdate && (
                <Flexbox direction={'column'} gap={2}>
                    <h4>{t('users:createModal:title')}</h4>
                    <p>{t('users:createModal:subtitle')}</p>
                </Flexbox>
            )}

            <Flexbox direction={'column'} gap={4}>
                <InputText
                    label={t(CREATE_USER_FORM_MODEL.firstName.label)}
                    type={'text'}
                    placeholder={t(CREATE_USER_FORM_MODEL.firstName.placeholder)}
                    errorMessage={t(errors.firstName?.message || '')}
                    {...register(t(CREATE_USER_FORM_MODEL.firstName.name))}
                />
                <InputText
                    label={t(CREATE_USER_FORM_MODEL.lastName.label)}
                    type={'text'}
                    placeholder={t(CREATE_USER_FORM_MODEL.lastName.placeholder)}
                    errorMessage={t(errors.lastName?.message || '')}
                    {...register(t(CREATE_USER_FORM_MODEL.lastName.name))}
                />
                <InputText
                    label={t(CREATE_USER_FORM_MODEL.email.label)}
                    type={'email'}
                    placeholder={t(CREATE_USER_FORM_MODEL.email.placeholder)}
                    errorMessage={t(errors.email?.message || '')}
                    {...register(t(CREATE_USER_FORM_MODEL.email.name))}
                />
            </Flexbox>
        </StyledCreateUserFormWrapper>
    )
}
