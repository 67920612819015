import React from 'react'
import {useTranslation} from 'react-i18next'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {XCloseIcon} from '@components/ui/icon/Icon.tsx'
import {
    StyledUpdateUserModalCloseButton,
    StyledUpdateUserModalContent,
    StyledUpdateUserModalHeader
} from '@/features/users/components/user-update-modal/style.ts'
import {User} from '@/features/users/types.ts'
import {UpdateUserForm} from '@/features/users/components/update-user-form/UpdateUserForm.tsx'
import {adaptUserToForm} from '@/features/users/utils.ts'

interface UserCreateModalProps {
    userToUpdate: User
    onCloseUpdateModal: () => void
}
export const UserUpdateModal: React.FC<UserCreateModalProps> = ({onCloseUpdateModal, userToUpdate}) => {
    const {t} = useTranslation()

    return (
        <Modal onOverlayClick={onCloseUpdateModal} width={444}>
            <StyledUpdateUserModalContent>
                <StyledUpdateUserModalHeader justify={'center'}>
                    <StyledUpdateUserModalCloseButton
                        shape={'square'}
                        size={'sm'}
                        variant={'ghost'}
                        onClick={onCloseUpdateModal}
                    >
                        <XCloseIcon />
                    </StyledUpdateUserModalCloseButton>
                    <p>{t('users:updateModal:header')}</p>
                </StyledUpdateUserModalHeader>
                <UpdateUserForm
                    defaultValues={adaptUserToForm(userToUpdate)}
                    userId={userToUpdate.id}
                    onCloseModal={onCloseUpdateModal}
                />
            </StyledUpdateUserModalContent>
        </Modal>
    )
}

UserUpdateModal.displayName = 'UserDeleteModal'
