import {createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants/routes.tsx'
import {UserRouteGuard} from '@route-guards/user-route/UserRouteGuard.tsx'
import {DashboardRouteGuard} from '@route-guards/dashboard-route/DashboardRouteGuard.tsx'
import {TicketRouteGuard} from '@route-guards/ticket-route/TicketRouteGuard.tsx'
import {ConversationRouteGuard} from '@route-guards/conversation-route/ConversationRouteGuard.tsx'

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route {...routes.HOME} />
            <Route element={<PrivateRoute />}>
                <Route element={<PrivateLayout withHeader />}>
                    <Route element={<UserRouteGuard />}>
                        <Route {...routes.USERS} />
                    </Route>
                    <Route element={<DashboardRouteGuard />}>
                        <Route {...routes.DASHBOARD} />
                    </Route>
                    <Route element={<TicketRouteGuard />}>
                        <Route {...routes.TICKETS} />
                    </Route>
                    <Route element={<ConversationRouteGuard />}>
                        <Route {...routes.CONVERSATION_HISTORY} />
                    </Route>
                </Route>
            </Route>
            <Route element={<PublicRoute />}>
                <Route element={<PublicLayout />}>
                    <Route {...routes.LOGIN} />
                    <Route {...routes.FORGOT_PASSWORD} />
                    <Route {...routes.EMAIL_SENT} />
                    <Route {...routes.CHANGE_PASSWORD} />
                    <Route {...routes.SET_PASSWORD} />
                </Route>
            </Route>
            <Route {...routes.NOT_FOUND} />
        </Route>
    )
)
