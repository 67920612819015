import {FC} from 'react'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Skeleton} from '@components/ui/skeleton/Skeleton.tsx'
import {
    StyledDashboardMetricsSectionGrid,
    StyledDashboardTicketSectionGrid,
    StyledSkeleton
} from '@/features/dashboard/component/dashboard-data/Style.ts'

export const DashboardDataSkeleton: FC = () => {
    return (
        <Flexbox direction={'column'} gap={6}>
            <div>
                <Skeleton height={32} />
            </div>
            <StyledDashboardTicketSectionGrid>
                <Flexbox gap={6} direction={'column'}>
                    <Skeleton height={173} />
                    <Skeleton height={173} />
                </Flexbox>
                <Skeleton width={'100%'} height={374} />
            </StyledDashboardTicketSectionGrid>
            <div>
                <Skeleton height={32} />
            </div>
            <StyledDashboardMetricsSectionGrid>
                <Flexbox gap={6}>
                    <StyledSkeleton width={'100%'} height={120} />
                    <StyledSkeleton width={'100%'} height={120} />
                    <StyledSkeleton width={'100%'} height={120} />
                </Flexbox>
                <Flexbox gap={6}>
                    <StyledSkeleton width={'100%'} height={120} />
                    <StyledSkeleton width={'100%'} height={120} />
                </Flexbox>
            </StyledDashboardMetricsSectionGrid>
        </Flexbox>
    )
}
