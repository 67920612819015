import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledAsideOverlay = styled.div(
    ({theme: {zIndex}}) => css`
        position: fixed;
        inset: 0;
        background: transparent;
        opacity: 0.6;
        z-index: ${zIndex.modalOverlay};
    `
)
export const StyledAside = styled.div(
    ({theme: {palette, zIndex, shadows}}) => css`
        position: fixed;
        top: 0;
        right: 0;
        z-index: ${zIndex.modal};
        background: ${palette.neutral.white};
        width: 400px;
        height: 100%;
        box-shadow: ${shadows.lg};
        display: grid;
        grid-template-rows: auto auto 1fr;
    `
)

export const StyledAsideHeader = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 5}px ${spacing * 2}px;
    `
)

export const StyledAsideTabContainer = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: 0 ${spacing * 4}px;
        border-bottom: 1px solid ${palette.neutral['300']};
    `
)
export const StyledAsideBody = styled.div(
    ({theme: {spacing, typography, palette}}) => css`
        padding: ${spacing * 6}px ${spacing * 4}px 0;
        overflow-y: auto;
        height: 100%;
        & p {
            overflow-wrap: anywhere;
            color: ${palette.neutral['900']};
            ${typography.textMd}
        }
    `
)
